@import "mains/fonts";
@import "mains/vars";

*,
*::after,
*::before {
	box-sizing: border-box;
}

html {
	height: 100%;
}
body {
	position: relative;
	max-width: 100vw;
	min-height: 100%;
	overflow-x: hidden;
	margin: 0;
	color: #212121;
	font-weight: 400;
	font-size: 16px;
	font-family: Poppins, sans-serif;
	line-height: 24px;
	background: #fafafa;
}

ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	background-color: transparent;
	border: none;
}

img {
	width: 100%;
	height: auto;
	object-fit: cover;
	line-height: 0;
}

main {
	padding-top: calc(
		(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
			((55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320))))
	);

	@media screen and (max-width: 480px) {
		padding-top: 0;
	}
}

.swiper-wrapper {
	display: flex;
	align-items: center;
	transition-timing-function: linear !important;
}

.ibg {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	img {
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
	}
}

.container {
	max-width: 1480px;
	margin: 0 auto;
	padding: 0 20px;

	&_small {
		max-width: 1280px;
	}
}
.wrapper {
	position: relative;
	overflow: clip;
	min-height: 100%;
}

.error {
	border: 1px solid #f24c4c !important;
}
//++++++ BUTTONS ++++++++++++++++++++++++++++++++++++++++//

.btn {
	display: inline-block;
	line-height: 24px;
	letter-spacing: 0.0015em;
	border: 1px solid #212121;
	border-radius: 5px;
	font: inherit;
	cursor: pointer;

	&:hover {
		color: #212121;
		background: transparent;
		border: 1px solid #212121;
	}

	&:disabled {
		background-color: rgb(158, 157, 157);
		border: 1px solid transparent;
		cursor: auto;
		&:hover {
			color: #fff;
		}
	}

	&_blue {
		color: #fff;
		background: #101f42;
		border: 1px solid transparent;
	}

	&_blue_outline {
		color: #101f42;
		background: transparent;
		border: 1px solid #101f42;
	}

	&_white {
		color: #212121;
		background: #fff;
		border: 1px solid transparent;

		&:hover {
			background: #212121;
			color: #fff;
		}
	}

	&_black {
		color: #fff;
		background: #212121;
		border: 1px solid transparent;

		&:hover {
			background: #fff;
			color: #212121;
			border: 1px solid transparent;
		}
	}

	&_tomato {
		color: #fff;
		background: #f24c4c;
		border: 1px solid transparent;

		&:hover {
			color: #f24c4c;
			background: transparent;
			border: 1px solid #f24c4c;
		}
	}
	&_tomato_outline {
		color: #f24c4c;
		background: transparent;
		border: 1px solid #f24c4c;

		&:hover {
			color: #fff;
			background: #f24c4c;
			border: 1px solid #f24c4c;
		}
	}
}

.btn-lang {
	position: relative;
	padding-right: 17px;
	line-height: 0;
	cursor: pointer;
	&::after {
		position: absolute;
		top: 50%;
		right: 0;
		width: 12px;
		height: 7px;
		background: url("../img/arrow-down.svg");
		background-repeat: no-repeat;
		transform: translateY(-50%);
		content: "";
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ Header ++++++++++++++++++++++++++++++++++++++++//

.main-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background: #fff;

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 991px) {
			padding: 20px 0;
		}
	}
}
.main-logo {
	max-width: 264px;
	@media screen and (max-width: 1230px) {
		max-width: 248px;
	}
	@media screen and (max-width: 480px) {
		max-width: 200px;
	}
}

.main-nav {
	@media screen and (max-width: 991px) {
		display: none;
	}
	&__list {
		display: flex;
		align-items: center;
		gap: 30px;
		line-height: 25px;
		letter-spacing: 0.0015em;
		@media screen and (max-width: 1230px) {
			gap: 15px;
		}
	}
	&__elem {
		padding: 38px 0;

		&:hover a ~ .mega-menu {
			max-height: 100vh;
			border-top: 1px solid #f1f1f1;
		}
	}
	&__link--collapce {
		position: relative;
		padding-right: 17px;

		&::after {
			position: absolute;
			top: 50%;
			right: 0;
			width: 12px;
			height: 7px;
			background: url("../img/arrow-down.svg");
			background-repeat: no-repeat;
			transform: translateY(-50%);
			content: "";
		}
	}
}

.mega-menu {
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
	z-index: 998;
	max-height: 0;
	overflow: hidden;
	background: #fff;
	border-top: 0 solid transparent;
	transition: all 0.5s linear;

	&.open {
		max-height: 100vh;
		border-top: 1px solid #f1f1f1;
	}
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 70px;
		padding: 36px 0 45px;

		@media screen and (max-width: 991px) {
			gap: 90px;
			justify-content: flex-end;
		}
	}
	&__right {
		margin-right: 64px;
		margin-left: auto;

		@media screen and (max-width: 991px) {
			flex-basis: 100%;
			margin-right: 0;
		}
	}
	&__contact-btn {
		padding: 11px 38px;
		@media screen and (max-width: 991px) {
			text-align: center;
			border: 1px solid #fff;
		}
	}
}
.user-nav {
	@media screen and (max-width: 991px) {
		display: none;
	}

	&__list {
		display: flex;
		align-items: center;
	}

	&__btn {
		padding: 11px 34px;
		@media screen and (max-width: 1110px) {
			display: none;
		}
	}

	&__elem {
		&_lang {
			position: relative;
			margin-left: 15px;
		}
	}
}

.burger-btn {
	position: relative;
	display: none;
	width: 37px;
	height: 16px;

	@media screen and (max-width: 991px) {
		display: block;
	}

	& span {
		position: absolute;
		right: 0;
		display: block;
		width: 37px;
		height: 2px;
		background: #212121;
		border-radius: 36px;

		&:first-child {
			top: 0;
		}

		&:nth-child(2) {
			top: 50%;
			width: 30px;
			transform: translateY(-50%);
		}

		&:last-child {
			top: 100%;
			transform: translateY(-50%);
		}
	}

	&.open span {
		&:first-child {
			top: 50%;
			transform: translateY(-50%);
			transform: rotate(45deg);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:last-child {
			top: 50%;
			transform: translateY(-50%);
			transform: rotate(-45deg);
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ SERVICES ++++++++++++++++++++++++++++++++++++++++//
.all-service__services {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.services {
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 140px;

		@media screen and (max-width: 776px) {
			flex-direction: column;
			gap: 30px;
			align-items: center;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media screen and (max-width: 776px) {
			width: 100%;
			padding-right: 0;
		}
	}

	& .service {
		@media screen and (max-width: 776px) {
			align-items: center;
		}

		&__list {
			@media screen and (max-width: 776px) {
				width: 100%;
				padding-left: 0;
				text-align: center;
			}
		}

		&__title {
			@media screen and (max-width: 991px) {
				padding-left: 60px;
			}
			@media screen and (max-width: 776px) {
				padding-left: 0px;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ SERVICE ++++++++++++++++++++++++++++++++++++++//

.service {
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
	}
	&__icon {
		width: 43px;
		height: 43px;
		object-fit: contain;
	}
	&__title {
		position: relative;
		margin: 0;
		margin-bottom: 21px;
		padding-left: 60px;
		color: #f24c4c;
		font-weight: 600;
		font-size: 20px;
		line-height: 43px;
		letter-spacing: 0.0015em;

		@media screen and (max-width: 991px) {
			padding-left: 0;
		}
	}
	& &__icon ~ &__title {
		padding-left: 0;
		margin-left: 20px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 14px;
		padding-left: 60px;
		color: #a3a3a3;
	}

	&__elem {
		&:hover {
			color: #f24c4c;
		}
		&_all {
			padding-top: 20px;
			margin-top: auto;
			color: #212121;

			@media screen and (max-width: 991px) {
				color: #fff;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ Mobile Menu ++++++++++++++++++++++++++++++++++++++//

.mobile-menu {
	top: 100px;
	left: -200%;
	width: 0;
	min-height: 100vh;
	overflow-y: scroll;
	text-align: right;
	background: #101f42;

	@media screen and (max-width: 480px) {
		top: 90px;
	}

	&::-webkit-scrollbar {
		width: 0;
	}

	&.show {
		left: 0;
		right: 0;
		width: 100%;
		padding: 80px 20px 120px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ LANG LIST +++++++++++++++++++++++++++++++++++++//
.lang-list {
	position: absolute;
	top: 65px;
	right: 0;
	left: 0;
	z-index: 998;
	max-height: 0;
	padding: 0;
	overflow: hidden;
	background: #fff;
	border-radius: 0 0 10px 10px;
	box-shadow: 0 0 25px rgb(0 0 0 / 10%);
	transition: max-height 0.5s linear;

	&.show {
		max-height: 300px;
	}

	&__item {
		padding: 10px;

		&:not(:last-child) {
			border-bottom: 1px solid rgb(204 204 204);
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ MAIN SECTION +++++++++++++++++++++++++++++++++++//

.main-section {
	position: absolute;
	display: flex;
	align-items: flex-end;
	top: calc((55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320))) * -1);
	left: 50%;
	transform: translateX(-50%);
	background-color: #ec9b3b;
	width: calc(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320)));
	height: calc(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320)));
	border-radius: 50%;
	overflow: hidden;
	background-color: #ec9b3b;
	color: #fff;

	@media (max-width: 480px) {
		position: relative;
		width: 100%;
		height: auto;
		top: 0;
		left: 0;
		transform: none;
		border-radius: 0;
		padding-top: 90px;
	}

	&__content {
		width: 100vw;

		margin: 0 auto;
		height: calc(
			(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
				(55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320))) - 150px
		);

		@media screen and (max-width: 480px) {
			height: calc(
				(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
					(55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320)))
			);
		}
	}

	&__wrapper {
		display: flex;
		position: relative;

		@media (max-width: 991px) {
			height: 100%;
			justify-content: center;
			align-items: center;
		}
	}

	&__left {
		width: 50%;
		padding: 40px 0 68px 100px;
		@media (max-width: 1280px) {
			padding-top: 0;
			width: 55%;
		}
		@media (max-width: 1100px) {
			padding-left: 80px;
		}
		@media screen and (max-width: 991px) {
			padding: 0;
			text-align: center;
			width: 100%;
		}
	}

	&__btn {
		padding: 14px 28px;
	}

	&__title {
		margin: 0;
		margin-bottom: 20px;
		font-weight: 700;
		font-size: 40px;
		line-height: 60px;

		@media screen and (max-width: 1300px) {
			font-size: 35px;
			line-height: 55px;
		}

		@media screen and (max-width: 991px) {
			max-width: 505px;
			margin: 0 auto;
			margin-bottom: 20px;
		}

		@media screen and (max-width: 480px) {
			font-size: 32px;
		}
	}

	&__description {
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 26px;

		&_short {
			@media screen and (max-width: 1200px) {
				display: none;
			}
		}
		@media screen and (max-width: 1300px) {
			font-size: 18px;
			line-height: 28px;
		}
		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__contact {
		display: flex;
		gap: 20px;
		align-items: center;

		@media screen and (max-width: 1240px) {
			gap: 10px;
		}
		@media screen and (max-width: 991px) {
			flex-direction: column;
		}
	}

	&__call {
		color: #1e61c7;
		font-size: 17px;
		text-decoration: underline;

		&:hover {
			color: #fff;
		}
	}

	&__right {
		position: relative;
		width: 50%;

		@media (max-width: 1280px) {
			width: 45%;
		}
		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__slider {
		position: absolute;
		top: 0;
		left: 20%;
		width: 1600px;

		@media (max-width: 1240px) {
			left: 10%;
		}

		@media screen and (max-width: 991px) {
			display: none;
		}

		& img {
			border-radius: 10px;
		}
	}
	&__slide {
		max-width: 530px;
	}

	&_double {
		.main-section__content {
			max-width: 50%;

			@media screen and (max-width: 991px) {
				display: flex;
				max-width: 510px;
				align-items: center;
				text-align: center;
				height: calc(
					(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
						(55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320)))
				);
			}
			@media screen and (max-width: 480px) {
				max-width: 360px;
			}
		}
		.main-section__image {
			background-position: left top;
			flex-basis: 50%;
			height: calc(
				(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
					(55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320)))
			);
			@media screen and (max-width: 991px) {
				display: none;
			}
		}
		.main-section__right {
			width: 60%;
			padding-top: calc(40px + (200 - 100) * ((100vw - 991px) / (1920 - 991)));
			padding-left: calc(10px + (145 - 70) * ((100vw - 991px) / (1920 - 991)));
			@media screen and (max-width: 1300px) {
				width: 70%;
			}
			@media screen and (max-width: 991px) {
				display: block;
				padding-top: 0;
				padding-left: 0;
				width: 100%;
				padding: 0 10px;
			}
		}
	}

	&_big {
		padding-top: 90px;
		.main-section__image {
			filter: brightness(60%);
			background-position: center top;
			flex-basis: 100%;
			max-width: 100vw;
			margin: 0 auto;
			height: calc(
				(600px + (2412 - 600) * ((100vw - 320px) / (1920 - 320))) -
					(55px + (1513 - 55) * ((100vw - 320px) / (1920 - 320)))
			);
		}

		.main-section__content {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			max-width: none;
			width: 100vw;
			margin: 0 auto;
			padding: 0;

			@media screen and (max-width: 480px) {
				align-items: center;
			}
		}

		.main-section__content-wrapper {
			max-width: 1440px;
			padding: 0 20px;
			margin: 0 auto;
		}

		.main-section__title {
			max-width: 972px;
			font-weight: 700;
			font-size: 92px;
			line-height: 138px;
			text-align: center;

			color: #ffffff;
			padding-bottom: 120px;

			@media screen and (max-width: 991px) {
				max-width: 750px;
				font-size: 60px;
				line-height: 107px;
				padding-bottom: 80px;
			}
			@media screen and (max-width: 480px) {
				font-size: 50px;
				line-height: 97px;
				padding-bottom: 80px;
			}
		}
	}

	&_blue {
		background-color: #101f42;
		color: #fff;
	}
	&_red {
		background-color: #f24c4c;
		color: #fff;
	}
	&_yellow {
		background-color: #f7d716;
		color: #212121;
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++++ TITLES +++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.title {
	margin: 0;
	color: #101f42;

	&_medium {
		font-weight: 700;
		font-size: 50px;
		line-height: 75px;

		@media screen and (max-width: 991px) {
			font-size: 45px;
		}
		@media screen and (max-width: 776px) {
			font-size: 30px;
		}
	}
	&_medium-m {
		font-weight: 600;
		font-size: 60px;
		line-height: 90px;

		@media screen and (max-width: 991px) {
			font-size: 50px;
			line-height: 75px;
		}

		@media screen and (max-width: 776px) {
			font-size: 30px;
			line-height: 45px;
		}
	}

	&_large {
		font-weight: 600;
		font-size: 70px;
		line-height: 125.5%;
		@media screen and (max-width: 991px) {
			font-size: 55px;
		}
		@media screen and (max-width: 776px) {
			font-size: 35px;
		}
	}

	&_red {
		font-weight: 700;
		font-size: 33px;
		line-height: 50px;
		color: #f24c4c;

		@media screen and (max-width: 991px) {
			font-size: 25px;
			line-height: 38px;
		}
		@media screen and (max-width: 480px) {
			font-size: 17px;
			line-height: 30px;
		}

		&_small {
			font-weight: 500;
			font-size: 24px;
			line-height: 36px;

			@media screen and (max-width: 776px) {
				font-size: 18px;
				line-height: 24px;
			}
		}

		&_medium {
			font-weight: 500;
			font-size: 30px;
			line-height: 125%;

			@media screen and (max-width: 776px) {
				font-size: 20px;
			}
		}

		&_big {
			font-size: 50px;
			line-height: 75px;
			@media screen and (max-width: 991px) {
				font-size: 45px;
				line-height: 70px;
			}
			@media screen and (max-width: 480px) {
				font-size: 35px;
				line-height: 60px;
			}
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++ CONTACT SECTION +++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.contact-info {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 70px;
	}
}

.contact-section {
	&__wrapper {
		display: flex;
		justify-content: center;

		@media screen and (max-width: 776px) {
			flex-direction: column;
			gap: 30px;
			align-items: center;
		}
	}

	&__content {
		padding-right: 15px;
		padding-left: 80px;
		@media screen and (max-width: 776px) {
			padding: 0;
		}
	}

	&__title {
		margin-bottom: 25px;
	}
	&__subtitle {
		max-width: 379px;
		margin: 0;
		color: #000;
		font-weight: 500;
		font-size: 18px;
		line-height: 180%;

		@media screen and (max-width: 991px) {
			font-size: 14px;
		}
	}
	&__address {
		color: #000;
		font-weight: 300;
		font-size: 18px;
		line-height: 190.5%;

		@media screen and (max-width: 991px) {
			font-size: 14px;
		}
	}
	&__grup {
		display: flex;
		gap: 33px;
		align-items: center;
		margin-bottom: 22px;
	}

	&__phone,
	&__email {
		cursor: pointer;
		color: #f24c4c;
		font-weight: 400;
		font-size: 20px;
		line-height: 30px;
		text-decoration-line: underline;

		&:hover {
			color: #d31417;
		}

		@media screen and (max-width: 991px) {
			font-size: 15px;
			line-height: 23px;
		}
	}

	&__btn {
		padding: 16px 174px;
		margin-top: 30px;

		@media screen and (max-width: 991px) {
			width: 100%;
			padding: 16px 74px;
			text-align: center;
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++++ FULL PERSON +++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.full-person {
	max-width: 381px;
	text-align: center;

	&_left {
		text-align: left;
	}

	&__photo {
		margin: 0;
		margin-bottom: 44px;

		@media screen and (max-width: 991px) {
			margin-bottom: 20px;
		}

		& img {
			width: 320px;
			height: 320px;
			border-radius: 50%;

			@media screen and (max-width: 575px) {
				width: 258px;
				height: 258px;
				border-radius: 50%;
			}
		}

		&_elips {
			img {
				width: 295px;
				height: 345px;
				border-radius: 0 182.477px;

				@media screen and (max-width: 575px) {
					width: 258px;
					height: 258px;
					border-radius: 50%;
				}
			}
		}

		&_comp {
			margin-bottom: 20px;
			img {
				width: 100%;
				max-width: 236px;
				height: auto;
				border-radius: 0;
			}
		}
	}

	&__text {
		position: relative;
		margin: 0;
		margin-bottom: 27px;
		padding: 0 65px;
		color: #101f42;
		font-size: 18px;
		line-height: 27px;
		text-align: center;

		@media screen and (max-width: 991px) {
			display: none;
		}

		&::after {
			position: absolute;
			top: -4px;
			left: 0;
			width: 38px;
			height: 33px;
			background: url("../img/left-quotes.svg");
			content: "";
		}

		&::before {
			position: absolute;
			right: 0;
			bottom: -4px;
			width: 38px;
			height: 33px;
			background: url("../img/right-quotes.svg");
			content: "";
		}

		&_red {
			padding: 0;
			margin-top: 17px;
			text-align: left;

			&::after {
				position: absolute;
				top: 5px;
				left: -40px;
				width: 26px;
				height: 20px;
				background: url("../img/left-red-quote.svg");
				content: "";
			}

			&::before {
				position: absolute;
				right: 0px;
				bottom: 0;
				width: 26px;
				height: 20px;
				background: url("../img/right-red-quote.svg");
				content: "";
			}

			span {
				color: #f24c4c;
			}
		}
	}

	&__name {
		margin: 0;
		color: #212121;
		font-weight: 600;
		font-size: 41.472px;
		line-height: 62px;

		@media screen and (max-width: 991px) {
			font-size: 32px;
			line-height: 48px;
		}
	}

	&__position {
		margin: 0;
		color: #bebebe;
		font-weight: 700;
		font-size: 24.8832px;
		line-height: 37px;

		&_red {
			font-size: 20px;
			font-weight: 400;
			line-height: 30px;
			color: #f24c4c;
		}

		@media screen and (max-width: 991px) {
			font-size: 19px;
			line-height: 29px;
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ BEADCRUMBS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.breadcrumbs {
	margin-bottom: 10px;

	@media screen and (max-width: 991px) {
		display: none;
	}

	& span {
		padding-right: 10px;
		cursor: pointer;
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}

		& svg {
			margin-left: 10px;
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ FOOTER ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.main-footer {
	margin-top: 150px;
	position: absolute;
	display: flex;
	left: 50%;
	width: 160vw;
	// width: calc(680px + (2322 - 680) * ((100vw - 320px) / (1920 - 320)));
	min-width: 2322px;
	height: calc(644px + (1017 - 644) * ((100vw - 320px) / (1920 - 320)));
	transform: translateX(-50%);
	background-color: #ec9b3b;
	color: #fff;
	border-radius: 50%;
	overflow: hidden;
	top: calc(98% - 663px);

	&__content {
		width: 100vw;
		padding: 0 80px;
		margin: 0 auto;

		@media (max-width: 991px) {
			justify-content: center;
		}

		&-wrapper {
			padding: 171px 20px 150px;
			box-sizing: content-box;
			max-width: 1480px;
			margin: 0 auto;
			display: flex;
			gap: 100px 80px;
			flex-wrap: wrap;

			justify-content: space-around;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		flex-basis: 220px;
		@media (max-width: 660px) {
			text-align: center;
		}

		&_social {
			justify-self: flex-end;
		}

		&-title {
			margin: 0;
			margin-bottom: 21px;
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 7px;
		height: 100%;
		&_social {
			flex-direction: row;
			gap: 27px;
			@media (max-width: 660px) {
				justify-content: center;
			}
		}
	}

	&__elem {
		&_all {
			padding-top: 40px;
			margin-top: auto;
			a {
				color: #fff;
			}
		}
	}

	&__link {
		display: inline-block;
		color: #ffd499;
		font-weight: 300;
		font-size: 18px;
		line-height: 32px;

		&:hover {
			color: #fff;
		}
	}

	&__social-link:hover svg path {
		fill: #ffd499;
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ CITY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.city {
	&_contact {
		padding-top: 150px;
	}

	&__title {
		text-align: center;
		max-width: 1167px;
		margin: 0 auto;
		margin-bottom: 84px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 42px;
		justify-content: center;
	}

	&__elem {
		flex-basis: 356px;
		perspective: 500px;
		&:hover .city__content {
			transform: rotateY(180deg);
		}
	}

	.map {
		width: 100%;
		height: 100%;
	}

	&__link {
		position: relative;
		display: block;
		cursor: pointer;
		z-index: 2;
	}
	&__content {
		position: relative;
		height: 356px;
		transition: transform 1s;
		transform-style: preserve-3d;
		border-radius: 50%;

		@media screen and (max-width: 480px) {
			height: 300px;
		}
	}
	&__front {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__front,
	&__back {
		position: absolute;
		height: 100%;
		width: 100%;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
	}

	&__name {
		text-align: center;
		font-weight: 700;
		font-size: 37.8112px;
		line-height: 57px;
		color: #f24c4c;
		margin-bottom: 15px;
	}

	&__photo {
		border-radius: 50%;
		overflow: hidden;
		img {
			width: 356px;
			height: 356px;
			border-radius: 50%;

			@media screen and (max-width: 480px) {
				width: 280px;
				height: 280px;
			}
		}
	}

	&__back {
		transform: rotateY(180deg);
	}

	&__adress-title {
		font-weight: 600;
		font-size: 17px;
		color: #101f42;
		text-align: center;
	}
	&__adress {
		font-weight: 300;
		font-size: 17px;
		line-height: 150%;
		text-align: center;
	}
	&__tel,
	&__email {
		color: #f24c4c;
		text-align: center;
		margin: 0;
		a {
			display: block;
			width: 100%;
			padding: 5px 10px;
			margin-bottom: 5px;

			@media screen and (max-width: 480px) {
				padding: 10px;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ Contact Us ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.contact-us {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 70px;
	}

	&__wrapper {
		position: relative;
		display: flex;
		align-items: center;

		@media screen and (max-width: 991px) {
			justify-content: center;
		}
	}

	&__title {
		margin: 0;
		margin-bottom: 66px;
		color: #212121;
		font-weight: 700;
		font-size: 70px;
		line-height: 105px;

		@media screen and (max-width: 991px) {
			font-size: 55px;
			line-height: 82px;
			margin-bottom: 30px;
		}

		@media screen and (max-width: 776px) {
			font-size: 40px;
			line-height: 62px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 26px;
		width: 100%;
		min-width: 563px;

		@media screen and (max-width: 991px) {
			min-width: auto;
			align-items: center;
			max-width: 532px;
		}
	}

	&__label {
		display: flex;
		justify-content: space-between;
		color: #212121;
		font-weight: 500;
		font-size: 16px;
		line-height: 125.5%;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			gap: 16px;
			width: 100%;
		}
	}

	&__input {
		width: 100%;
		max-width: 340px;
		padding: 12px 21px;
		font: inherit;
		font-weight: 400;
		background: transparent;
		border: 1px solid #323232;
		border-radius: 4px;

		@media screen and (max-width: 991px) {
			max-width: 532px;
		}
	}

	&__textarea {
		width: 100%;
		max-width: 340px;
		height: 146px;
		font: inherit;
		font-weight: 400;
		padding: 12px 21px;
		background: transparent;
		border: 1px solid #323232;
		border-radius: 4px;
		resize: none;

		@media screen and (max-width: 991px) {
			max-width: 532px;
		}
	}

	&__btn {
		padding: 13px 32px;
		align-self: flex-end;
		width: 100%;
		max-width: 340px;

		@media screen and (max-width: 991px) {
			align-self: center;
			max-width: 532px;
		}
	}
	&__photo {
		transform: translateX(35%);
		width: 952px;
		height: 952px;
		border-radius: 50%;
		border: 15px solid #f24c4c;
		padding: 65px;

		@media screen and (max-width: 1200px) {
			transform: translateX(10%);
		}

		@media screen and (max-width: 991px) {
			display: none;
		}

		img {
			width: 803px;
			height: 803px;
			border-radius: 50%;
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
//++++++++++++++ FAQ +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.faq {
	&__spollers {
		padding-top: 70px;

		@media screen and (max-width: 480px) {
			padding-top: 40px;
		}
	}
	&__title {
		text-align: center;
		font-weight: 700;
		font-size: 50px;
		line-height: 75px;
		margin-bottom: 50px;
		color: #101f42;
		&--red {
			color: #f24c4c;
		}
		@media screen and (max-width: 776px) {
			font-size: 40px;
			line-height: 55px;
			margin-bottom: 50px;
		}
		@media screen and (max-width: 480px) {
			font-size: 30px;
			line-height: 45px;
			margin-bottom: 50px;
		}
	}
}
.faq__careers {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.careers__faq-title {
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 50px;
}
.careers__faq-spollers {
	padding: 0;
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
//++++++++++++ SPOLLERS +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.spollers {
	max-width: 1020px;
	margin: 0 auto;
	display: grid;
	border: 1px solid #e5e7eb;
	border-radius: 6px;

	&__item {
		font-size: 20px;
		font-weight: 400;
		line-height: 150%;
		color: #6d7280;
		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 150%;
		}
	}

	&__title {
		width: 100%;
		cursor: default;
		text-align: left;
		padding: 25px 112px 25px 40px;
		position: relative;
		font-weight: 600;
		font-size: 22.95px;
		line-height: 150%;
		color: #6d7280;

		@media screen and (max-width: 776px) {
			padding: 25px 60px 25px 40px;
			font-size: 18px;
			line-height: 150%;
		}
		@media screen and (max-width: 480px) {
			padding: 25px 40px 25px 20px;
			font-size: 18px;
			line-height: 150%;
		}

		._spoller-init & {
			cursor: pointer;
			&::before,
			&::after {
				content: "";
				position: absolute;
				right: 40px;
				top: 50%;
				background-color: #6d7280;
				height: 2px;
				width: 9px;
				transition: transform 0.5s ease 0s;

				@media screen and (max-width: 480px) {
					right: 20px;
				}
			}
			&::before {
				transform: translate(-75%, -50%) rotate(40deg);
			}
			&::after {
				transform: translate(0, -50%) rotate(-40deg);
			}
			&:hover {
				color: #101f42;
				&::before,
				&::after {
					background-color: #101f42;
				}
			}
			&--red:hover {
				color: #f24c4c;
				&::before,
				&::after {
					background-color: #f24c4c;
				}
			}
			&._spoller-active {
				color: #101f42;
				background: #f3f4f6;
				&::before,
				&::after {
					background-color: #101f42;
				}
				&::before {
					transform: translateX(-75%) rotate(-40deg);
				}
				&::after {
					transform: rotate(40deg);
				}
			}
			&--red._spoller-active {
				color: #f24c4c;
				&::before,
				&::after {
					background-color: #f24c4c;
				}
			}
		}
	}

	&__body {
		padding: 25px 40px;
		border: 1px solid #e5e7eb;
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ OUR CLIENTS +++++++++++++++++++++++++++++++++++++++++++++++++++//
.our-clients {
	position: relative;
	padding-top: 155px;
	text-align: center;

	@media screen and (max-width: 991px) {
		padding-top: 68px;
	}

	&__title {
		margin-bottom: 85px;
		font-weight: 700;
		font-size: 36px;
		line-height: 54px;
	}
	&__name {
		margin: 0;
		max-width: 310px;
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;

		text-align: center;
		letter-spacing: 1.53933px;
		text-transform: uppercase;

		color: #212121;
	}
	&__slider {
		width: 115vw;
	}
	&__slide {
		height: 100%;
		display: flex;
		max-width: 460px;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 0 75px;
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;

		img {
			max-width: 260px;
			margin-bottom: 40px;
		}

		&:not(:last-child)::after {
			position: absolute;
			top: 50%;
			right: 0;
			width: 1px;
			height: 135px;
			background: #efefef;
			border-radius: 7px;
			transform: translateY(-50%);
			content: "";
		}
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ CALCULATION +++++++++++++++++++++++++++++++++++++++++++++++++++//

.calculation-rates {
	padding-top: 112px;
	@media screen and (max-width: 991px) {
		padding-top: 70px;
	}
}

.calculation {
	&__title {
		margin-bottom: 111px;
		text-align: center;

		@media screen and (max-width: 991px) {
			margin-bottom: 50px;
		}
	}

	&__form {
		display: flex;
		justify-content: center;
		gap: 30px;

		@media screen and (max-width: 1220px) {
			gap: 15px;
		}
		@media screen and (max-width: 991px) {
			flex-direction: column;
			gap: 40px;
			align-items: center;
		}

		&-grup {
			padding: 30px 20px 27px 25px;
			background: #fff;
			border-radius: 20px;
			box-shadow: 0 4px 112px rgb(219 49 54 / 5%);

			&_calc {
				flex-basis: 610px;
			}

			&_estimation {
				flex-basis: 464px;
			}

			&_calc,
			&_estimation {
				@media screen and (max-width: 991px) {
					flex-basis: auto;
					width: 100%;
					max-width: 610px;
				}
			}
		}

		&-title {
			font-weight: 600;
			margin-bottom: 26px;
			text-align: center;
		}

		&-pair {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media screen and (max-width: 776px) {
				flex-direction: column;
				gap: 15px;
				align-items: baseline;
			}
			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}

		&-subtitle {
			margin: 0;
			padding-right: 5px;
			color: #212121;
			font-weight: 500;
			line-height: 125.5%;
		}

		&-dropdawn {
			position: relative;
			flex-basis: 197px;

			@media screen and (max-width: 776px) {
				flex-basis: auto;
				width: 100%;
			}

			&_large {
				flex-basis: 340px;
				@media screen and (max-width: 776px) {
					flex-basis: auto;
					width: 100%;
				}
			}
		}

		&-input {
			flex-basis: 340px;
			padding: 14px 15px;
			font-size: 14px;
			line-height: 125.5%;
			border: 1px solid #d8d8d8;
			border-radius: 4px;

			@media screen and (max-width: 776px) {
				flex-basis: auto;
				width: 100%;
			}
		}

		&-label {
			position: relative;
			padding-left: 28px;
			color: #000;
			font-weight: 500;
			line-height: 125.5%;
			&:hover {
				color: #f24c4c;
			}
			& input:checked ~ .calculation__radio-box {
				background: #f24c4c;
				border: 1px solid transparent;
				&::after {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 6px;
					height: 6px;
					background: #fff;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					content: "";
				}
			}
		}

		&-radio {
			position: absolute;
			width: 0;
			height: 0;
			cursor: pointer;
			opacity: 0;
		}
		&-meaning {
			margin: 0;
			color: #a3a3a3;
		}
		&-sign {
			margin: 0;
			margin-bottom: 35px;
			color: #b9b9b9;
			font-size: 10px;
			line-height: 125.5%;
		}

		&-submite {
			width: 100%;
			padding: 23px 28px;
			font-weight: 600;
			font-size: 20px;
			line-height: 125.5%;

			@media screen and (max-width: 776px) {
				padding: 18px 28px;
				font-size: 16px;
			}
		}
	}
	&__radio-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		margin-right: 75px;
		@media screen and (max-width: 776px) {
			gap: 25px;
			margin-top: 20px;
			margin-right: 0;
		}
	}
	&__radio-box {
		position: absolute;
		top: 50%;
		left: 0;
		width: 18px;
		height: 18px;
		border: 1px solid #d9d9d9;
		border-radius: 50%;
		transform: translateY(-50%);
	}
	&__dropdawn-btn {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 14px;
		line-height: 125.5%;
	}
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ DROPDAWN+++++++++++++++++++++++++++++++++++++++++++++++++++//

.dropdawn__select-btn,
.dropdawn li {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dropdawn__select-btn {
	position: relative;
	padding: 12px 35px 12px 21px;
	color: #000;
	font-weight: 400;
	border: 1px solid #d8d8d8;
	border-radius: 4px;

	&::after {
		position: absolute;
		top: 50%;
		right: 21px;
		width: 12px;
		height: 7px;
		background-image: url("../img/arrow-down.svg");
		transform: translateY(-50%);
		content: "";
	}

	span {
		pointer-events: none;
	}
}

.dropdawn--multiple__select-btn {
	flex-wrap: wrap;
	gap: 5px;
	min-height: 48px;
	padding: 8px 35px 8px 9px;

	& span {
		display: flex;
		pointer-events: all;
		align-items: center;
		padding: 6px 12px 6px 6px;
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		line-height: 125.5%;
		background: #212121;
		border-radius: 3px;

		& svg {
			margin-right: 6px;
		}
	}
}
.dropdawn__content {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 2;
	display: none;
	padding: 20px;
	background: #fff;
	border-radius: 7px;
	box-shadow: 0 10px 25px rgb(0 0 0 / 10%);
}

.dropdawn.active .dropdawn__content {
	display: block;
}

.dropdawn__search {
	position: relative;
	& input {
		width: 100%;
		padding: 10px 20px 10px 43px;
		font-size: 16px;
		border: 1px solid #b3b3b3;
		border-radius: 5px;
		outline: none;

		&:focus {
			padding-left: 42px;
			border: 2px solid #4285f4;
		}
		&::placeholder {
			color: #bfbfbf;
		}
	}
}

.dropdawn__content .dropdawn__options {
	max-height: 250px;
	margin-top: 10px;
	padding-right: 7px;
	overflow-y: auto;
}
.dropdawn__options {
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 25px;
	}
	&::-webkit-scrollbar-thumb {
		background: #ccc;
		border-radius: 25px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #b3b3b3;
	}
	& li {
		padding: 15px 13px;
		font-size: 16px;
	}
}
.dropdawn__options p {
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	padding: 20px;
}
.dropdawn__options li:hover,
.dropdawn li.selected {
	background: #f2f2f2;
	border-radius: 5px;
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++ BENEFITS ++++++++++++++++++++++++++++++++++++++++++++++++++++//
.rates__benefits {
	padding-top: 150px;
}
.main__benefits {
	padding-top: 150px;
}
.benefits {
	@media screen and (max-width: 991px) {
		display: none;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 72px 0 144px 0;
		color: #fff;
		background-color: #f24c4c;
		border-radius: 0 0
			calc((0px + (500 - 0) * ((100vw - 1300px) / (1920 - 1300)))) 0;
		@media screen and (min-width: 1921px) {
			border-radius: 0 0 500px 0;
		}

		&_blue {
			color: #fff;
			background-color: #101f42;
		}
	}

	&__title {
		max-width: 988px;
		margin: 0 auto;
		margin-bottom: 116px;
		font-weight: 700;
		font-size: 60px;
		line-height: 90px;
		text-align: center;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 1132px;
		margin: 0 auto;
		gap: 48px 0;
		font-size: 32px;
		line-height: 48px;
		letter-spacing: 0.015em;

		@media screen and (max-width: 1300px) {
			gap: 48px calc((20px + (90 - 20) * ((100vw - 991px) / (1300 - 991))));
		}
	}

	&__item {
		display: flex;
		align-items: center;
		width: 50%;
		svg {
			margin-right: 40px;
			path {
				stroke: #101f42;
			}
		}

		&_red-marker {
			svg path {
				stroke: #f24c4c;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ TRANSLATIONS RATES ++++++++++++++++++++++++++++++++++++//

.translation-rates {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}

	h2,
	h3 {
		margin: 0;
		margin-bottom: 25px;
		color: #f24c4c;
	}

	h2 {
		font-weight: 700;
		font-size: 33px;
		line-height: 50px;

		@media screen and (max-width: 991px) {
			font-size: 25px;
			line-height: 38px;
		}
		@media screen and (max-width: 480px) {
			font-size: 17px;
			line-height: 30px;
		}
	}

	h3 {
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;

		@media screen and (max-width: 776px) {
			font-size: 18px;
			line-height: 24px;
		}
	}

	p {
		margin: 0;
		margin-bottom: 60px;
		font-size: 16px;
		line-height: 180%;

		@media screen and (max-width: 776px) {
			margin-bottom: 20px;
			font-size: 14px;
		}
	}

	a {
		color: #f24c4c;
		text-decoration: underline;
	}

	&__table {
		margin-bottom: 53px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ RATES TABLE+++++++++++++++++++++++++++++++++++++++++++++++++++++//

.rates__table {
	width: 100%;

	color: #212121;
	font-size: 21px;
	line-height: 125.5%;
	border-collapse: collapse;

	@media screen and (max-width: 991px) {
		font-size: 16px;
	}

	&-head {
		color: #fff;
		line-height: 180%;
		text-align: left;
		background: #101f42;

		th {
			width: 33.33%;
			padding: 14px;

			@media screen and (max-width: 991px) {
				padding: 14px 6px;
			}
		}
	}
	&-body {
		td {
			width: 33.33%;
			padding: 9px 16px;

			@media screen and (max-width: 991px) {
				padding: 9px 6px;
			}
		}
		tr:nth-child(even) {
			background: #e3e3e3;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ DESCRIPTION +++++++++++++++++++++++++++++++++++++++++++++++++++//

.lang__description,
.all-languages__description,
.disciplines__description,
.careers__description {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.careers__description {
	position: relative;
	z-index: 1;
}

.description {
	&_lang {
		padding-top: 150px;
		padding-bottom: 210px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__see {
		cursor: pointer;
		line-height: 180%;
		font-size: 16px;
		color: #d8d8d8;
	}

	h2 {
		margin: 0;
		color: #f24c4c;
		max-width: 1144px;
		text-align: center;
		font-size: 50px;
		line-height: 75px;
		@media screen and (max-width: 991px) {
			font-size: 45px;
			line-height: 70px;
		}
		@media screen and (max-width: 480px) {
			font-size: 35px;
			line-height: 60px;
		}
	}

	p {
		max-width: 898px;
		color: #000;
		font-weight: 300;
		line-height: 187.5%;
		letter-spacing: 0.04em;
		text-align: center;
	}

	&__btn {
		padding: 23px 112px;
		font-weight: 600;
		font-size: 20px;
		line-height: 125.5%;

		@media screen and (max-width: 480px) {
			padding: 23px 40px;
		}
	}
}

.lang__description {
	&_sb {
		h2 {
			max-width: 898px;
		}
		p {
			max-width: 1131px;
		}
	}
}

.careers__description {
	p {
		margin-bottom: 40px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ INFORMATION CARDS+++++++++++++++++++++++++++++++++++++++++++++++++++++//
.all-languages__information-cards,
.disciplines__information-cards {
	padding-top: 102px;
}

.information-cards {
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 72px;

		@media screen and (max-width: 1278px) {
			justify-content: space-around;
		}

		&_big {
			gap: 51px 20px;
		}
	}
}

.information-card {
	display: flex;
	flex: 0 1 365px;
	flex-direction: column;
	align-items: center;
	// max-width: 360px;

	&_big {
		flex: 0 1 400px;
		@media screen and (max-width: 991px) {
			flex: 0 1 365px;
		}
	}

	&__header {
		display: flex;
		gap: 30px;
		width: 100%;
		margin-bottom: 12px;

		@media screen and (max-width: 695px) {
			justify-content: center;
		}
		@media screen and (max-width: 480px) {
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 0;
		}

		&_full {
			width: 100%;
		}
	}

	&__img {
		width: 127px;
		height: 127px;
		border-radius: 50%;
	}

	&__info {
		padding-top: 15px;
	}

	&__title {
		margin: 0;
		color: #000;
		font-weight: 500;
		font-size: 24.0499px;
		line-height: 187.5%;
		letter-spacing: 0.04em;

		&_big {
			font-weight: 600;
			font-size: 26px;
		}

		&_medium {
			font-weight: 600;
		}
	}

	&__link {
		color: #f24c4c;
		font-weight: 300;
		font-size: 17.575px;
		line-height: 187.5%;
		letter-spacing: 0.04em;
		text-decoration-line: underline;
	}

	&__text {
		margin: 0;
		color: #000;
		font-size: 16.65px;
		line-height: 187.5%;
		letter-spacing: 0.04em;
		@media screen and (max-width: 695px) {
			max-width: 300px;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++ Steps +++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.methods__steps {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ STEP +++++++++++++++++++++++++++++++++++++++++++++++++++++//
.step {
	position: relative;
	display: flex;

	gap: 30px;
	padding: 0 0 286px 0;

	@media screen and (max-width: 991px) {
		gap: 15px;
		padding: 0 0 186px 0;
	}
	@media screen and (max-width: 720px) {
		flex-direction: column;
		align-items: center;
		padding: 0 0 56px 0;
	}

	&:not(:last-child)::after {
		position: absolute;
		bottom: -30px;
		left: 0;
		z-index: -1;
		display: block;
		width: 100%;
		height: 429px;
		background: url("../img/dots-line.svg");
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 65%;
		content: "";

		@media screen and (max-width: 1200px) {
			background-size: 70%;
			transform: rotate(10deg);
		}

		@media screen and (max-width: 991px) {
			background-size: 75%;
			transform: rotate(12deg);
		}

		@media screen and (max-width: 720px) {
			display: none;
		}
	}
	&:last-child {
		padding-bottom: 0;
	}

	&__img {
		max-width: 100%;
		width: 505px;
		height: 505px;
		object-fit: contain;

		@media screen and (max-width: 1200px) {
			width: 470px;
			height: 470px;
		}
		@media screen and (max-width: 991px) {
			width: 370px;
			height: 370px;
		}
		@media screen and (max-width: 480px) {
			width: 280px;
			height: 280px;
		}
		img {
			height: 100%;
			border: 12px solid #fafafa;
			border-radius: 50%;
		}

		&_cylinder {
			flex-shrink: 2;
			width: 693px;
			height: 362px;
			@media screen and (max-width: 720px) {
				width: 370px;
				height: 370px;
			}
			@media screen and (max-width: 480px) {
				width: 280px;
				height: 280px;
			}
			img {
				border-radius: 0px 500px 500px 0px;
				@media screen and (max-width: 720px) {
					border-radius: 50%;
				}
			}
		}
		&_petal {
			height: 546px;

			@media screen and (max-width: 1200px) {
				width: 470px;
				height: 515px;
			}
			@media screen and (max-width: 991px) {
				width: 370px;
				height: 415px;
			}
			@media screen and (max-width: 720px) {
				width: 370px;
				height: 370px;
			}
			@media screen and (max-width: 480px) {
				width: 280px;
				height: 280px;
			}
			img {
				border-radius: 0px 320px;
				@media screen and (max-width: 991px) {
					border-radius: 0px 220px;
				}
				@media screen and (max-width: 720px) {
					border-radius: 50%;
				}
			}
		}
	}

	&__content {
		width: 50%;
		padding: 35px 0;
		@media screen and (max-width: 720px) {
			width: 100%;
		}
	}

	&__number {
		display: inline-block;
		margin: 0;
		margin-bottom: 19px;
		padding: 3px 29px;
		color: #101f42;
		font-weight: 900;
		font-size: 28px;
		line-height: 163%;
		border: 2px dashed #f24c4c;
		border-radius: 4px;

		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__title {
		margin: 0;
		margin-bottom: 25px;
		color: #f24c4c;
		font-weight: 700;
		font-size: 33px;
		line-height: 50px;
		@media screen and (max-width: 991px) {
			margin-bottom: 10px;
			color: #101f42;
			font-size: 26px;
			line-height: 30px;
		}
	}
	&__text {
		margin: 0;
		line-height: 24px;

		@media screen and (max-width: 991px) {
			font-size: 14px;
		}
	}

	&:nth-child(even) {
		justify-content: flex-end;
		padding-right: 100px;
		text-align: right;

		@media screen and (max-width: 1220px) {
			padding-right: 0px;
		}
		.step__img {
			order: 1;
			@media screen and (max-width: 720px) {
				order: 0;
			}
		}
		.step__img {
			transform: scale(-1, 1);
		}

		&::after {
			background: url("../img/dots-line-even.svg");
			background-repeat: no-repeat;
			background-position: center bottom;
			background-size: 65%;
			@media screen and (max-width: 1200px) {
				background-size: 70%;
				transform: rotate(-12deg);
			}
			@media screen and (max-width: 991px) {
				background-size: 75%;
				transform: rotate(-6deg);
			}
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ TRANSLATIONS INFO+++++++++++++++++++++++++++++++++++++++++++++++++++++//
.translations-info {
	position: relative;
	padding-top: 110px;

	@media screen and (max-width: 991px) {
		padding-top: 60px;
	}

	&__wrapper {
		display: flex;
		gap: 90px;

		@media screen and (max-width: 1200px) {
			gap: 40px;
		}
		@media screen and (max-width: 660px) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__img {
		position: -webkit-sticky;
		position: sticky;
		top: 120px;
		margin-top: 60px;
		align-self: flex-start;
		flex-shrink: 0;
		width: 100%;
		max-width: 536px;

		@media screen and (max-width: 991px) {
			max-width: 360px;
		}
		@media screen and (max-width: 776px) {
			max-width: 280px;
		}
		@media screen and (max-width: 660px) {
			position: static;
			align-self: center;
		}

		img {
			height: 536px;
			border-radius: 50%;
			@media screen and (max-width: 991px) {
				height: 360px;
			}
			@media screen and (max-width: 776px) {
				height: 280px;
			}
		}
	}

	&__content {
		max-width: 691px;
		padding-top: 34px;

		@media screen and (max-width: 776px) {
			padding-top: 0;
		}
	}

	h2 {
		margin: 0;
		margin-bottom: 20px;
		color: #f24c4c;
		font-weight: 500;
		font-size: 30px;
		line-height: 125%;

		@media screen and (max-width: 776px) {
			font-size: 20px;
		}
	}

	p {
		margin: 0;
		margin-bottom: 30px;
		color: #000;
		font-weight: 300;
		font-size: 16px;
		line-height: 187.5%;
		letter-spacing: 0.04em;

		@media screen and (max-width: 991px) {
			margin-bottom: 32px;
			line-height: 167.5%;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ SPECIALITY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.chinese__speciality {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 69px;
	}
}
.main__speciality {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 69px;
	}
}
.speciality {
	position: relative;

	&__left {
		flex-basis: 50%;
		padding: 100px 74px 100px 100px;

		@media screen and (max-width: 1230px) {
			padding: 60px 69px 100px 60px;
		}
		@media screen and (max-width: 776px) {
			display: flex;
			flex-basis: 100%;
			flex-direction: column;
			align-items: center;
			padding: 50px 0 100px;
		}
	}

	&_red {
		background: linear-gradient(90deg, #f24c4c 55%, transparent 50%);
		color: #fff;

		@media screen and (max-width: 776px) {
			background: #f24c4c;
		}

		.speciality__left {
			background: #f24c4c;
		}
	}
	&_blue {
		background: linear-gradient(90deg, #101f42 55%, transparent 50%);
		color: #fff;

		@media screen and (max-width: 776px) {
			background: #101f42;
		}
		.speciality__left {
			background: #101f42;
		}
	}

	&__wrapper {
		display: flex;
	}

	&__right {
		flex-basis: 50%;
		border-radius: 0px 70% 0px 0px;
		overflow: hidden;
		@media screen and (max-width: 992px) {
			border-radius: 0;
		}
		@media screen and (max-width: 776px) {
			display: none;
		}
	}

	&__title {
		margin-bottom: 100px;
		color: inherit;
		@media screen and (max-width: 1230px) {
			margin-bottom: 60px;
		}
		@media screen and (max-width: 480px) {
			font-size: 25px;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 29px 36px;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 1.3px;
		text-transform: uppercase;

		@media screen and (max-width: 1230px) {
			gap: 20px 20px;
			font-size: 16px;
			letter-spacing: 1px;
		}

		@media screen and (max-width: 991px) {
			font-size: 12px;
			line-height: 16px;
		}
	}
	&__item {
		position: relative;
		svg {
			flex-shrink: 0;
		}

		@media screen and (max-width: 991px) {
			gap: 10px;

			svg {
				transform: scale(0.5);
			}
		}
		@media screen and (max-width: 776px) {
			white-space: normal;
		}

		a {
			display: flex;
			align-items: center;
			gap: 20px;
			white-space: nowrap;
		}

		a:hover {
			color: #101f42;
		}

		&_yellow {
			a:hover {
				color: #ec9b3b;
			}
			svg {
				#circle_1 {
					stroke: #ec9b3b;
				}
				#circle_2 {
					fill: #ec9b3b;
				}
			}
		}
	}
	&__img {
		height: 100%;
		object-position: left top;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ GET QUOTE +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.lang__get-quote,
.general__get-quote {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 70px;
	}
}

.get-quote {
	text-align: center;

	&__title {
		margin-bottom: 50px;
	}

	&__form {
		display: flex;
		gap: 20px;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__form-section {
		position: relative;
		flex-basis: 400px;
		flex-grow: 1;
		padding: 30px 30px 46px;
		background: #fff;
		border-radius: 20px;
		box-shadow: 0 4px 112px rgb(219 49 54 / 5%);

		@media screen and (max-width: 991px) {
			flex-basis: auto;
			width: 100%;
			max-width: 530px;
		}
	}

	&__form-title {
		margin-bottom: 39px;
	}

	&__form-label {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 14px;
		color: #212121;
		font-weight: 500;
		font-size: 16px;
		line-height: 125.5%;
		text-align: left;
	}

	&__form-input {
		padding: 12px 21px;
		color: #000;
		font-weight: 400;
		border: 1px solid #d8d8d8;
		border-radius: 4px;
	}

	&__form-textarea {
		height: 141px;
		margin-top: 12px;
		margin-bottom: 20px;
		padding: 12px 21px;
		color: #000;
		font-weight: 400;
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		resize: none;
	}
	&__form-submite {
		margin-bottom: 28px;
		padding: 15px 34px;
		font-weight: 500;
		font-size: 16px;
		line-height: 125.5%;
	}

	&__form-info {
		b {
			color: #101f42;
			font-weight: 600;
			font-size: 12px;
			line-height: 125.5%;
		}
		p {
			padding-right: 30px;
			color: #000;
			font-weight: 400;
			font-size: 10px;
			line-height: 213%;
			a {
				color: #f24c4c;
				text-decoration: underline;
			}
		}
	}
	&__form-dropdawn {
		position: relative;
	}
	&__form-drag {
		position: relative;
		text-align: center;
		padding: 46px;
		border: 2px dashed #f24c4c;
		border-radius: 7px;

		input {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
		}

		p {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #000;
			font-weight: 500;
			font-size: 14px;
			line-height: 125.5%;

			svg {
				margin-bottom: 13px;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++ Page BG++++++++++++++++++++++++++++++++++++++++++++++++++++//
.page-bg {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 1920px;
	min-height: 100%;
	z-index: -1;

	@media screen and (max-width: 776px) {
		display: none;
	}

	&__img {
		position: relative;
		&_0 {
			height: 1345px;

			img {
				position: absolute;
				left: -225px;
				bottom: 0;
				width: 884px;
				height: 840px;
				object-fit: contain;

				@media screen and (max-width: 992px) {
					left: -125px;
				}
			}
		}

		&_1 {
			height: 1396px;

			img {
				position: absolute;
				right: 0;
				top: 0;
				width: 861px;
				height: 866px;
				object-fit: contain;
			}
		}

		&_2 {
			height: 1127px;

			img {
				position: absolute;
				right: -95px;
				top: 0;
				width: 900px;
				height: 884px;
				object-fit: contain;

				@media screen and (max-width: 992px) {
					right: -20px;
				}
			}
		}

		&_3 {
			height: 1200px;

			img {
				position: absolute;
				left: -130px;
				top: 0;
				width: 750px;
				height: 750px;
				object-fit: contain;

				@media screen and (max-width: 992px) {
					left: 0px;
				}
			}
		}

		&_4 {
			height: 1100px;

			img {
				position: absolute;
				right: 0;
				top: 0;
				width: 861px;
				height: 866px;
				object-fit: contain;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++ STATISTIC +++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.main__statistic {
	padding-top: 60px;
}
.statistic {
	color: #101f42;
	&__wrapper {
		padding: 57px 0;
		background: #fff;
		border-radius: 7px;
		box-shadow: 0 0 25px rgb(0 0 0 / 10%);

		@media screen and (max-width: 991px) {
			max-width: 577px;
			margin: 0 auto;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 37px 0;
		justify-content: center;
	}
	&__elem {
		flex-basis: 186px;
		flex-grow: 1;
		padding: 5px 27px;
		text-align: center;

		&:not(:last-child) {
			border-right: 1px solid #efefef;
		}
	}

	&__stat {
		margin: 0;
		margin-bottom: 16px;
		font-weight: 800;
		font-size: 60px;
		line-height: 76px;
		letter-spacing: 4.75px;
		text-transform: uppercase;
	}

	&__stat-title {
		margin: 0;
		font-weight: 700;
		font-size: 21px;
		line-height: 25px;
		letter-spacing: 1.5631px;
		text-transform: uppercase;
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ translation-agency +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.main__translation-agency {
	z-index: 2;
	padding-top: 110px;
	@media screen and (max-width: 991px) {
		padding-top: 94px;
	}
}
.translation-agency {
	position: relative;

	&__wrapper {
		position: relative;
		padding: 272px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: right;

		@media screen and (max-width: 991px) {
			padding: 0;
			justify-content: center;
			padding-right: 0;
			text-align: center;
		}
	}

	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 615px;
		background-size: contain;
		background-position: left center;

		@media screen and (max-width: 1200px) {
			width: 515px;
		}

		@media screen and (max-width: 991px) {
			display: none;
		}
	}
	&__inner {
		display: table-row;
	}
	&__left,
	&__right {
		display: table-cell;
	}
	&__left {
		width: 50%;
		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__content {
		display: table;
		width: 100%;
	}

	h2 {
		margin: 0;
		color: #f24c4c;
		font-weight: 700;
		margin-bottom: 20px;
		letter-spacing: 2.2974px;
		text-transform: uppercase;
		@media screen and (max-width: 776px) {
			font-size: 20px;
		}
	}

	p {
		margin-left: auto;
		max-width: 539px;
		margin-bottom: 30px;
		color: #212121;
		line-height: 25px;
		letter-spacing: 1.59px;

		@media screen and (max-width: 991px) {
			margin-right: auto;
		}

		@media screen and (max-width: 490px) {
			font-size: 14px;
			letter-spacing: 1px;
		}
	}

	&__btn {
		padding: 14px 28px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ block-bg ++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.block-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	&__elem {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}

	@media screen and (max-width: 991px) {
		display: none;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ reviews ++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.main__reviews {
	position: relative;
	margin-top: -460px;
	padding-top: 610px;
	@media screen and (max-width: 991px) {
		padding-top: 100px;
		margin-top: 0;
	}
}
.references__reviews {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}

.reviews {
	&__slide {
		max-width: 1187px;
		margin: 0 auto;
		padding: 0 15px;
	}

	&__title {
		text-align: center;
		margin-bottom: 50px;
	}

	&__img {
		width: 227px;
		height: 227px;
		margin: 0 auto;
		margin-bottom: 28px;
		border: 8px solid #f24c4c;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__awesom {
		margin-bottom: 17px;
		color: #f24c4c;
		font-weight: 800;
		font-size: 26px;
		line-height: 39px;
		text-align: center;

		@media screen and (max-width: 991px) {
			font-size: 21px;
			line-height: 32px;
		}
	}

	&__text {
		position: relative;
		margin-bottom: 26px;
		padding: 0 81px;
		color: #212121;
		font-size: 20px;
		line-height: 30px;

		&::after,
		&::before {
			content: "";
			position: absolute;
			width: 38px;
			height: 33px;
		}
		&::after {
			top: -44px;
			left: 0;
			background: url("../img/left-quotes.svg");
		}

		&::before {
			right: 0;
			bottom: -33px;
			background: url("../img/right-quotes.svg");
		}

		@media screen and (max-width: 991px) {
			padding: 0 50px;
			font-size: 16px;
			line-height: 25px;
		}

		&_red {
			color: #f24c4c;
		}
	}

	&__name {
		padding: 0 81px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;

		@media screen and (max-width: 991px) {
			padding: 0 50px;
			font-size: 16px;
			line-height: 25px;
		}
	}
}

.reviews-pagination.swiper-horizontal > .swiper-pagination-bullets,
.reviews .swiper-pagination-bullets.swiper-pagination-horizontal,
.reviews .swiper-pagination-custom,
.reviews .swiper-pagination-fraction {
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	margin-top: 55px;
}

.reviews-pagination .swiper-pagination-bullet {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: transparent;
	border: 1px solid #f24c4c;
	opacity: 1;
}

.reviews-pagination .swiper-pagination-bullet-active {
	background: #f24c4c;
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ how-work +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.main__how-work {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 116px;
	}
}
.how-work {
	&__img {
		width: 50%;
		border-radius: 0 181px 0 0;

		@media screen and (max-width: 991px) {
			display: none;
		}
	}
	&__slider {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		padding-bottom: 30px;
		min-height: 362px;

		@media screen and (max-width: 991px) {
			width: auto;
			min-height: auto;
			max-width: 420px;
		}

		.swiper-wrapper {
			height: auto;
		}
	}

	&__slide {
		padding: 0 80px;

		@media screen and (max-width: 991px) {
			padding: 0;
		}
	}

	&__slider-wrapper {
		text-align: center;
	}

	&__btns {
		display: flex;
		gap: 20px;
		justify-content: center;
		margin-bottom: 29px;

		button {
			padding: 5px 20px;

			&.active {
				color: #fff;
				background: #101f42;
				border: 1px solid transparent;
			}
		}

		@media screen and (max-width: 490px) {
			gap: 15px;
		}
	}

	&__btns .swiper-pagination-bullet {
		width: auto;
		height: auto;
		padding: 5px 18px;
		color: #212121;
		line-height: 24px;
		letter-spacing: 0.0015em;
		background: transparent;
		border: 1px solid #212121;
		border-radius: 5px;
		cursor: pointer;
		font: inherit;
		opacity: 1;

		&-active {
			color: #fff;
			background: #101f42;
			border: 1px solid transparent;
		}
	}

	&__list {
		width: 50%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 25px 10px;
		gap: 24px 10px;

		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__item {
		position: relative;
		padding-left: 35px;

		&::after {
			position: absolute;
			top: 50%;
			left: 0;
			width: 17px;
			height: 13px;
			background: url("../img/ok-red.svg");
			background-repeat: no-repeat;
			transform: translateY(-50%);
			content: "";
		}
	}

	&__top,
	&__bottom {
		display: flex;
	}

	&__bottom {
		align-items: center;
		justify-content: flex-end;

		@media screen and (max-width: 991px) {
			justify-content: center;
		}
	}

	&__title {
		margin-bottom: 20px;
		color: #000;
		font-weight: 600;
		letter-spacing: 0.015em;
	}

	&__descr {
		margin: 0;
		text-align: center;
	}

	&__btn {
		position: relative;
		width: 50%;
		align-self: stretch;
		font-size: 29px;
		line-height: 34px;
		letter-spacing: 0.015em;
		text-align: left;
		padding: 20px 60px;

		border-radius: 0 0 0 75px;

		@media screen and (max-width: 991px) {
			width: auto;
			padding: 0;
			color: #101f42;
			text-align: center;
			background-color: transparent;

			&:hover {
				color: #f24c4c;
				border-color: transparent;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ discover-blog +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.main__discover-blog {
	padding-top: 157px;

	@media screen and (max-width: 991px) {
		padding-top: 116px;
	}
	@media screen and (max-width: 480px) {
		padding-top: 70px;
	}
}
.discover-blog {
	&__title {
		font-size: 60px;
		line-height: 125%;
		text-align: center;
		margin-bottom: 60px;

		@media screen and (max-width: 991px) {
			font-size: 40px;
		}
		@media screen and (max-width: 480px) {
			font-size: 35px;
		}
	}

	&__list {
		max-width: 1340px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-bottom: 50px;
	}

	&__elem {
		padding: 0 20px;
		margin-bottom: 40px;
		flex: 0 0 calc(33.33% - 30px);
		min-width: 300px;

		@media screen and (max-width: 991px) {
			flex: 0 0 calc(50% - 30px);
		}
		@media screen and (max-width: 680px) {
			flex: 0 0 100%;
			padding: 0;
		}
	}

	&__img {
		width: 100%;
		height: 296px;
		border-radius: 7px;
		margin-bottom: 13px;
	}

	&__elem-title {
		font-weight: 600;
		font-size: 27.1186px;
		line-height: 125%;

		color: #000000;

		@media screen and (max-width: 991px) {
			font-size: 22px;
		}
	}

	&__descr {
		margin: 0;
		font-size: 21.6949px;
		line-height: 33px;

		color: #b3b3b3;

		@media screen and (max-width: 991px) {
			font-size: 18px;
		}
	}
	&__btn {
		text-align: center;
		max-width: 300px;
		display: block;
		padding: 19px 42px;
		font-size: 21.6949px;
		line-height: 33px;
		margin: 0 auto;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ meet-team ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.about-us__meet-team {
	padding-top: 169px;
	padding-bottom: 100px;
	@media screen and (max-width: 991px) {
		padding-bottom: 89px;
	}
}
.meet-team {
	position: relative;
	overflow: hidden;
	text-align: center;

	&::after {
		position: absolute;
		top: 90px;
		left: 50%;
		z-index: -1;
		width: 100%;
		color: #101f42;
		font-weight: 700;
		font-size: 172px;
		line-height: 258px;
		text-align: center;
		transform: translateX(-50%);
		opacity: 0.05;
		content: "MEET OUR TEAM";

		@media screen and (max-width: 991px) {
			top: 330px;
			font-size: 110px;
		}

		@media screen and (max-width: 776px) {
			display: none;
		}
	}

	&__wrapper {
		display: flex;
		gap: 100px;
		transition: transform 0.5s linear;

		&.open {
			transform: translateX(-100%);
		}
	}

	&__title {
		margin-bottom: 179px;
		color: #212121;

		@media screen and (max-width: 991px) {
			margin-bottom: 135px;
		}

		@media screen and (max-width: 776px) {
			margin-bottom: 0;
		}
	}

	&__content {
		min-width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		gap: 98px 0;

		@media screen and (max-width: 776px) {
			gap: 0;
		}
	}

	&__person {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;

		@media screen and (max-width: 776px) {
			padding-top: 76px;
		}

		&_big {
			width: 33%;

			@media screen and (max-width: 776px) {
				width: 50%;
			}

			@media screen and (max-width: 464px) {
				width: 100%;
			}

			&_last {
				@media screen and (max-width: 776px) {
					width: 100%;
				}
			}
		}

		&_up {
			margin-top: -100px;

			@media screen and (max-width: 776px) {
				margin-top: 0px;
			}
		}

		&_small {
			width: 20%;

			@media screen and (max-width: 776px) {
				width: 33.3%;
			}

			@media screen and (max-width: 464px) {
				width: 50%;
			}
		}
	}

	&__text {
		padding: 0 51px;
	}

	&__photo {
		margin-bottom: 15px;
		img {
			width: 158px;
			height: 158px;
			border-radius: 50%;

			@media screen and (max-width: 1280px) {
				width: 126px;
				height: 126px;
			}
		}

		&_elips {
			img {
				width: 177px;
				height: 208px;
				border-radius: 0 110px;

				@media screen and (max-width: 1280px) {
					width: 141px;
					height: 166px;
					border-radius: 0 87px;
				}

				@media screen and (max-width: 776px) {
					width: 126px;
					height: 126px;
					border-radius: 50%;
				}
			}
		}

		&_small {
			img {
				width: 104px;
				height: 104px;

				@media screen and (max-width: 1280px) {
					width: 83px;
					height: 83px;
				}
			}
		}
	}

	&__name {
		margin: 0;
		font-weight: 600;
		font-size: 30px;
		line-height: 45px;

		@media screen and (max-width: 1280px) {
			font-size: 23.9901px;
			line-height: 36px;
		}

		&_small {
			font-size: 20px;
			line-height: 30px;

			@media screen and (max-width: 1280px) {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	&__position {
		margin: 0;
		color: #bebebe;
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;

		@media screen and (max-width: 1280px) {
			font-size: 14.3941px;
			line-height: 22px;
		}

		&_small {
			font-size: 14px;
			line-height: 21px;

			@media screen and (max-width: 1280px) {
				font-size: 11px;
				line-height: 17px;
			}
		}
	}

	&__slider {
		position: relative;
		min-width: 100%;
		height: 100%;
		padding-top: 50px;

		@media screen and (max-width: 776px) {
			padding-top: 100px;
		}
	}

	&__close-btn {
		position: absolute;
		top: 0;
		left: 0;
		padding: 15px;
		cursor: pointer;

		@media screen and (max-width: 776px) {
			top: 20px;
		}
	}

	&__next-slide {
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 10;
		max-width: 245px;
		transform: translate(50%, -50%);
		cursor: pointer;

		@media screen and (max-width: 575px) {
			top: 55%;
		}

		.meet-team__name,
		.meet-team__position {
			@media screen and (max-width: 575px) {
				display: none;
			}
		}
	}

	&__slide {
		display: flex;
		align-items: center;
		min-width: 100%;
		min-height: 600px;
		padding-right: 210px;
		padding-left: 15px;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			padding-right: 15px;
		}
	}

	&__slide-left {
		display: flex;
		flex-direction: column;
		min-width: 354px;
		padding-right: 20px;

		@media screen and (max-width: 991px) {
			padding-right: 0;
			padding-bottom: 80px;
		}
		@media screen and (max-width: 776px) {
			min-width: auto;
			padding: 0 20px 80px;
		}
	}

	&__slide-photo {
		margin-bottom: 24px;

		img {
			width: 320px;
			height: 320px;
			border-radius: 50%;

			@media screen and (max-width: 575px) {
				width: 258px;
				height: 258px;
				border-radius: 50%;
			}
		}

		&_elips {
			img {
				width: 295px;
				height: 345px;
				border-radius: 0 182.477px;

				@media screen and (max-width: 575px) {
					width: 258px;
					height: 258px;
					border-radius: 50%;
				}
			}
		}
	}

	&__slide-name {
		margin: 0;
		font-weight: 600;
		font-size: 49.7664px;
		line-height: 75px;

		@media screen and (max-width: 575px) {
			padding-top: 80px;
		}
	}

	&__slide-position {
		margin: 0;
		color: #bebebe;
		font-weight: 700;
		font-size: 29.8598px;
		line-height: 45px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++++ timeline ++++++++++++++++++++++++++++++++++++++++++++++++++//
.about-us__timeline {
	min-height: 1045px;
	padding: 261px 15px 210px;

	@media screen and (max-width: 991px) {
		min-height: auto;
		padding: 51px 15px 120px;
	}

	@media screen and (max-width: 776px) {
		padding: 51px 15px 85px;
	}
}
.timeline {
	color: #fff;
	background: url("../img/timeline-bg.png");
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	background-size: 100% 100%;

	@media screen and (max-width: 991px) {
		background: #101f42;
		border-radius: 0 0 417px;
	}
	@media screen and (max-width: 776px) {
		border-radius: 0;
	}

	&__inner {
		position: relative;
	}

	&__svg {
		width: 100%;

		@media screen and (max-width: 991px) {
			display: none;
			width: auto;
			max-width: 100%;
		}

		&_small {
			display: none;

			@media screen and (max-width: 776px) {
				display: inline-block;
			}
		}

		&_medium {
			display: none;

			@media screen and (max-width: 991px) {
				display: inline-block;
			}

			@media screen and (max-width: 776px) {
				display: none;
			}
		}
	}

	&__title {
		color: #fff;
		margin-bottom: 77px;
		text-align: center;

		@media screen and (max-width: 991px) {
			margin-bottom: 66px;
		}
	}

	&__text {
		position: absolute;
		max-width: 370px;
		cursor: default;
		opacity: 0;
		transition: opacity 0.5s linear;

		@media screen and (max-width: 991px) {
			opacity: 1;
		}

		&.hover {
			opacity: 1;
		}

		&_first {
			top: 20px;
			left: 310px;

			@media screen and (max-width: 991px) {
				left: 230px;
			}
			@media screen and (max-width: 776px) {
				left: 142px;
			}
		}

		&_second {
			bottom: -140px;
			left: 50%;
			text-align: center;
			transform: translateX(-50%);

			@media screen and (max-width: 991px) {
				bottom: 40px;
				left: 230px;
				text-align: left;
				transform: translateX(0%);
			}
			@media screen and (max-width: 776px) {
				bottom: 40px;
				left: 142px;
			}
		}

		&_third {
			top: 20px;
			right: 310px;
			text-align: right;

			@media screen and (max-width: 991px) {
				top: 50%;
				right: 0;
				left: 190px;
				transform: translateY(-50%);
			}
			@media screen and (max-width: 776px) {
				left: 142px;
				text-align: left;
				transform: translateY(-50%);
			}
		}
	}

	&__text-title {
		margin: 0;
		margin-bottom: 10px;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;

		@media screen and (max-width: 776px) {
			font-size: 20px;
			line-height: 24px;
		}

		@media screen and (max-width: 460px) {
			font-size: 15px;
			line-height: 18px;
		}
	}

	&__text-descr {
		margin: 0;
		color: #f3f3f3;

		@media screen and (max-width: 776px) {
			font-size: 14px;
			line-height: 17px;
		}

		@media screen and (max-width: 460px) {
			font-size: 9px;
			line-height: 14px;
		}
	}

	&__line {
		&.hover {
			opacity: 1;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++ right-border-block +++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.right-top-border-block {
	border-radius: 0px 510px 0px 0px;
	@media screen and (max-width: 1600px) {
		border-radius: 0px 310px 0px 0px;
	}
	@media screen and (max-width: 991px) {
		border-radius: 0px 510px 0px 0px;
	}
	@media screen and (max-width: 776px) {
		border-radius: 0;
	}

	&_orange {
		color: #fff;
		background-color: #ec9b3b;
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++ values+++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.about-us__values {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 0;
	}
}
.values {
	position: relative;

	&::after {
		position: absolute;
		top: 103px;
		left: 0;
		z-index: 1;
		width: calc(
			50% - calc(300px + (380 - 300) * ((100vw - 991px) / (1920 - 991)))
		);
		height: 133px;
		background: #101f42;
		border-radius: 0 22px 22px 0;
		content: "";

		@media screen and (max-width: 991px) {
			top: 150px;
			width: 50%;
		}
		@media screen and (max-width: 480px) {
			display: none;
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		padding: 103px 0 119px;
		align-items: center;

		// new
		justify-content: space-between;
		gap: 30px;

		//
		@media screen and (max-width: 991px) {
			padding: 150px 0 100px;
		}

		@media screen and (max-width: 760px) {
			padding: 150px 0 50px;
		}

		@media screen and (max-width: 460px) {
			padding: 51px 0 70px;
		}
	}

	&__title {
		position: relative;
		color: #fff;
		z-index: 2;
		font-size: 61px;
		line-height: 92px;
		@media screen and (max-width: 460px) {
			text-align: center;
		}
	}

	&__subtitle {
		position: relative;
		z-index: 2;
		margin: 0;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 0.015em;
		@media screen and (max-width: 460px) {
			text-align: center;
		}
	}

	&__left {
		width: 50%;

		@media screen and (max-width: 991px) {
			width: auto;
			padding: 0 20px;
		}
		@media screen and (max-width: 480px) {
			padding: 0;
		}
	}

	&__left-header {
		background-color: #101f42;
		border-radius: 22px;
		padding-bottom: 14px;
		margin-bottom: 84px;
		max-width: fit-content;
		padding-right: 57px;

		@media screen and (max-width: 480px) {
			max-width: 100%;
			padding-right: 0;
			background-color: transparent;
			font-size: 14px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		margin: 0;
		padding: 0;
	}

	&__item {
		position: relative;
		flex-basis: 320px;
		flex-grow: 1;
		max-width: 605px;
	}

	h4 {
		margin: 0;
		font-weight: 800;
		font-size: 24px;
		line-height: 36px;
		letter-spacing: 0.015em;
		color: #101f42;
		margin-bottom: 15px;
	}

	&__right {
		width: 40%;
		position: sticky;
		top: 140px;
		align-self: flex-start;

		@media screen and (max-width: 991px) {
			display: none;
		}
		img {
			max-width: 610px;
		}
	}

	// &__right {
	// 	position: sticky;
	// 	top: 140px;
	// 	display: grid;
	// 	width: 50%;
	// 	grid-template-areas: "f f" "f f" "f f" "e s" "r s" "v s" "b q" "b q" "b q";
	// 	gap: 20px;
	// 	padding-left: 75px;
	// 	align-self: flex-start;

	// 	@media screen and (max-width: 1100px) {
	// 		gap: 10px;
	// 		padding-left: 40px;
	// 	}

	// 	@media screen and (max-width: 991px) {
	// 		display: none;
	// 	}
	// }

	// &-f {
	// 	grid-area: f;
	// }
	// &-e {
	// 	grid-area: e;
	// }
	// &-r {
	// 	grid-area: r;
	// }
	// &-v {
	// 	grid-area: v;
	// }
	// &-s {
	// 	grid-area: s;
	// }
	// &-b {
	// 	grid-area: b;
	// }
	// &-q {
	// 	grid-area: q;
	// }
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ vision ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.about-as__vision {
}
.vision {
	position: relative;
	background-image: url("../img/vision-bg.png");
	background-position: right bottom;
	background-repeat: no-repeat;
	background-color: #101f42;

	@media screen and (max-width: 1300px) {
		background-position: right -250px bottom;
	}
	@media screen and (max-width: 1100px) {
		background-image: none;
	}

	&::after {
		position: absolute;
		top: 103px;
		left: 0;
		z-index: 1;
		width: calc(
			50% - calc(300px + (380 - 300) * ((100vw - 991px) / (1920 - 991)))
		);
		height: 133px;
		background: #f24c4c;
		border-radius: 0 22px 22px 0;
		content: "";

		@media screen and (max-width: 991px) {
			top: 103px;
			width: 50%;
		}
		@media screen and (max-width: 480px) {
			display: none;
		}
	}

	&__inner {
		position: relative;
		padding-top: 103px;
		padding-bottom: 150px;

		@media screen and (max-width: 1280px) {
			padding-top: 103px;
			padding-bottom: 100px;
		}

		@media screen and (max-width: 480px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #f24c4c;
		height: 133px;
		border-radius: 22px;
		padding-bottom: 14px;
		margin-bottom: 84px;
		max-width: fit-content;
		padding-right: 57px;

		@media screen and (max-width: 480px) {
			max-width: 100%;
			padding-right: 0;
			background-color: transparent;
			font-size: 14px;
		}
	}
	&__title {
		position: relative;
		color: #fff;
		z-index: 2;
		font-size: 61px;
		line-height: 92px;
		@media screen and (max-width: 480px) {
			text-align: center;
		}
	}

	&__content {
		display: flex;
	}
	&__content-left {
		width: 75%;
		@media screen and (max-width: 1300px) {
			width: 80%;
		}
		@media screen and (max-width: 1100px) {
			width: 100%;
		}
		p:nth-child(2) {
			max-width: 75%;

			@media screen and (max-width: 1100px) {
				max-width: 100%;
			}
		}

		p:nth-child(3) {
			max-width: 60%;
			@media screen and (max-width: 1300px) {
				max-width: 55%;
			}
			@media screen and (max-width: 1100px) {
				max-width: 100%;
			}
		}
	}
	&__content-right {
		width: 25%;

		@media screen and (max-width: 1300px) {
			width: 20%;
		}
		@media screen and (max-width: 1100px) {
			display: none;
		}
	}
	p {
		font-size: 24px;
		line-height: 36px;
		letter-spacing: 0.015em;
		color: #ffffff;
		margin: 0;
		margin-bottom: 30px;
		// max-width: 1200px;
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ stories ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.about-us__stories {
	padding-top: 150px;

	@media screen and (max-width: 776px) {
		padding-top: 60px;
	}
}
.stories {
	&__title {
		margin-bottom: 70px;
		color: #212121;
		text-align: center;
	}

	&__img {
		margin-bottom: 14px;
		border-radius: 7px;
	}

	&__name {
		margin: 0;
		color: #000;
		font-weight: 600;
		font-size: 27.1186px;
		line-height: 41px;
	}

	&__descr {
		margin: 0;
		color: #b3b3b3;
		font-size: 21.6949px;
		line-height: 33px;
	}
}

.stories-pagination {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.stories-pagination .swiper-pagination-bullet {
	display: inline-block;
	width: 15px;
	height: 15px;
	background: transparent;
	border: 1px solid #101f42;
	opacity: 1;
}

.stories-pagination .swiper-pagination-bullet-active {
	background: #101f42;
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ blog ++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.main__blog {
	position: relative;
	padding-top: 355px;
	margin-top: -198px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
		margin-top: 0;
	}
}
.blog {
	&__title {
		text-align: center;
		margin-bottom: 40px;
	}

	&__content {
		display: flex;
		gap: 16px;
		margin-bottom: 40px;
	}

	&__left {
		width: 31%;
		min-width: 415px;
		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	&__news {
		display: flex;
		flex-direction: column;
		gap: 17px;

		color: #000000;

		&:not(:last-child) {
			margin-bottom: 65px;
		}
	}

	&__news-title {
		margin: 0;
		font-weight: 700;
		font-size: 24px;
		line-height: 125.5%;
		color: #000000;
	}

	&__news-item {
		background-color: #fff;

		&:hover .blog__news-learn {
			svg {
				margin-left: 45px;
			}
		}
	}

	&__news-link {
		display: flex;
		cursor: pointer;
	}

	&__news-img {
		width: 129px;
		flex-shrink: 0;
	}

	&__news-content {
		padding: 23px 27px 23px 20px;
	}

	&__news-name {
		margin: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 125.5%;

		margin-bottom: 17px;
	}

	&__news-learn {
		margin: 0;
		font-weight: 600;
		display: flex;
		align-items: center;
		svg {
			margin-left: 20px;
			transition: margin-left 0.3s linear;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		max-width: 921px;

		@media screen and (max-width: 1200px) {
			margin: 0 auto;
		}
		@media screen and (max-width: 620px) {
			grid-template-columns: 1fr;
			max-width: 451px;
		}
		&_v1 {
			grid-template-columns: repeat(2, 1fr);
			@media screen and (max-width: 620px) {
				grid-template-columns: 1fr;
				max-width: 451px;
			}
		}
		&_v2 {
			.blog__item {
				&:nth-child(3n + 1) {
					display: flex;
					gap: 35px;
					grid-column-start: 1;
					grid-column-end: 3;

					@media screen and (max-width: 776px) {
						display: block;
						grid-column-end: 2;
					}

					.blog__content-inner {
						flex-grow: 1;
					}
					.blog__body {
						display: block;
						max-width: 373px;
					}
					.blog__img {
						margin-bottom: 0;
						height: 100%;
						max-height: none;

						@media screen and (max-width: 776px) {
							height: auto;
							margin-bottom: 20px;
						}
					}
				}
				.blog__info {
					@media screen and (max-width: 1200px) {
						flex-direction: row;
					}
					@media screen and (max-width: 900px) {
						flex-direction: column;
					}
				}
			}
		}

		&_v3 {
			grid-template-columns: repeat(3, 1fr);
			max-width: 100%;

			@media screen and (max-width: 991px) {
				grid-template-columns: repeat(2, 1fr);
			}
			@media screen and (max-width: 620px) {
				grid-template-columns: 1fr;
				max-width: 451px;
			}
		}
	}

	&__item {
		padding: 16px;
		background-color: #fff;
	}

	&__link {
		display: block;
	}
	&__img {
		max-height: 273px;
		margin-bottom: 20px;
	}

	&__body {
		max-width: 356px;
	}

	&__author {
		margin: 0;
		font-size: 18px;
		line-height: 125.5%;
		color: #8c8c8c;
		margin-bottom: 26px;
	}
	&__name {
		font-weight: 700;
		font-size: 26px;
		line-height: 125.5%;
		color: #000000;
		margin-bottom: 26px;

		@media screen and (max-width: 991px) {
			font-size: 22px;
		}
	}

	&__text {
		font-weight: 500;
		letter-spacing: 0.0015em;
		color: #8c8c8c;
		margin-bottom: 26px;
	}

	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 15px;

		@media screen and (max-width: 1340px) {
			flex-direction: column;
		}
	}

	&__date {
		margin: 0;
		font-weight: 600;
		letter-spacing: 0.0015em;
		color: #000000;

		span:not(:last-child) {
			padding-right: 30px;
			position: relative;

			&::after {
				position: absolute;
				content: "";
				width: 2px;
				height: 14px;
				background-color: #000;
				top: 50%;
				right: 14px;
				transform: translateY(-50%);
			}
		}
	}
	&__btns {
		margin: 0;
		display: flex;
		gap: 15px;

		@media screen and (max-width: 480px) {
			gap: 30px;
		}

		span,
		a {
			cursor: pointer;
		}
		&_like:hover {
			#like1,
			#like2 {
				fill: #d9d9d9;
			}
		}
		&_comment:hover {
			#comment1,
			#comment2 {
				fill: #d9d9d9;
			}
		}
		&_like.active {
			#like1,
			#like2 {
				fill: #f24c4c;
			}
		}
		&_comment.active {
			#comment1,
			#comment2 {
				fill: #f24c4c;
			}
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ share-popup +++++++++++++++++++++++++++++++++++++++++++++++++++++//
.share-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 100;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-top: 100px;
		padding-bottom: 20px;
	}

	&__content {
		position: relative;
		background: #ffffff;
		border-radius: 10px;
		padding: 40px;
		max-height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		@media screen and (max-width: 480px) {
			padding: 20px;
		}
	}

	&__links {
		margin-bottom: 30px;
	}

	&__title {
		margin: 0;
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		letter-spacing: -0.04em;
		color: #5e5e5e;
		margin-bottom: 20px;

		@media screen and (max-width: 480px) {
			margin-bottom: 40px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;

		gap: 30px;

		@media screen and (max-width: 480px) {
			justify-content: center;
		}
	}

	&__item {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		border: 3px solid #f2f2f6;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}
	}

	&__link-title {
		margin: 0;
		font-weight: 500;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: -0.04em;
		color: #5e5e5e;
		margin-bottom: 10px;
	}

	&__link-input {
		width: 100%;
		padding: 13px;
		margin-right: 20px;
		border: 2px solid #f2f2f6;
		border-radius: 6px;
		font-size: 30.6509px;
		line-height: 39px;
		letter-spacing: -0.04em;
		color: #9fa2ac;
	}

	&__link-inner {
		display: flex;
		@media screen and (max-width: 480px) {
			flex-direction: column;
			gap: 20px;
		}
	}

	&__link-btn {
		padding: 18px;
		background: #f2f3f7;
		border-radius: 6px;
		cursor: pointer;

		&:hover svg path {
			fill: #f24c4c;
		}
	}

	&__close {
		right: 40px;
	}
}
.close-popup {
	position: absolute;
	width: 40px;
	height: 40px;
	background: #f2f3f7;
	border-radius: 50%;
	cursor: pointer;

	&::after,
	&::before {
		content: "";
		position: absolute;
		width: 16px;
		height: 1px;
		top: 50%;
		left: 50%;
		background: #101f42;
	}
	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ main++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.main {
	&__bg {
		position: absolute;
		z-index: -1;

		&_references {
			background-image: url("../img/references-bg.png");
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% 100%;
			height: 2200px;
			width: 100%;
		}
		&_blog {
			background-image: url("../img/blog-main-bg.png");
			background-repeat: no-repeat;
			background-position: center top;
			background-size: 100% 100%;
			min-height: 1453px;
			width: 100%;
		}
		&_blog-inside {
			background-image: url("../img/blog-inside-bg.png");
			background-repeat: no-repeat;
			background-position: center top;
			background-size: 100% 100%;
			min-height: 1334px;
			width: 100%;
		}

		@media screen and (max-width: 991px) {
			display: none;
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++ descr-block +++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.references__descr-block {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}

.descr-block {
	&__inner {
		max-width: 900px;
	}
	h2 {
		margin: 0;
		font-weight: 700;
		font-size: 33px;
		line-height: 50px;
		color: #f24c4c;
		margin-bottom: 25px;

		@media screen and (max-width: 991px) {
			font-size: 25px;
			line-height: 38px;
		}
		@media screen and (max-width: 480px) {
			font-size: 17px;
			line-height: 30px;
		}
	}

	p {
		font-weight: 400;
		line-height: 180%;
		color: #000000;

		a {
			text-decoration: underline;
			color: #f24c4c;
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++++ featured-cases +++++++++++++++++++++++++++++++++++++++++++++++++++//

.references__featured-cases {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.featured-cases {
	&__title {
		text-align: center;
		margin-bottom: 60px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		gap: 42px;
	}

	&__elem {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-basis: 340px;
		flex-shrink: 1;
		flex-grow: 1;
		padding: 30px;
		background-color: #fff;
		box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
	}

	&__img {
		width: 212px;
		height: 212px;
		background-size: contain;
		margin-bottom: 26px;
	}

	&__name {
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 26px;
		color: #f24c4c;
	}

	&__btn {
		width: 100%;
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		color: #ffffff;
		padding: 20px 45px;
		text-align: center;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++ filter +++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.blog__filter {
	justify-content: center;
	margin-bottom: 87px;
	gap: 20px;

	@media screen and (max-width: 991px) {
		margin-bottom: 40px;
	}
}
.filter {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 20px 15px;

	&__item {
		font-size: 20px;
		line-height: 125%;
	}

	&__btn {
		cursor: pointer;
		font: inherit;
		padding: 11px;
		background: rgba(87, 97, 145, 0.08);
		border-radius: 10px;

		&_active {
			background: #f24c4c;
			color: #fff;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ marketing-translation ++++++++++++++++++++++++++++++++++++++++++++++++++++++//

.references__marketing-translation {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.marketing-translation {
	&__top {
		padding: 0 45px;
		margin-bottom: 60px;

		@media screen and (max-width: 991px) {
			padding: 0;
		}
	}

	&__title {
		margin-bottom: 30px;
	}

	&__descr {
		max-width: 916px;
		margin: 0;
		margin-bottom: 50px;
	}

	&__bottom {
		position: relative;
		margin-bottom: 50px;
	}
	&__bottom-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		padding-top: 35px;
		text-align: center;

		img {
			position: sticky;
			top: 120px;
			max-width: 748px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 20px 30px;
		@media screen and (max-width: 620px) {
			justify-content: center;
		}
	}

	&__item {
		width: calc(50% - 20px);
		align-self: stretch;
		min-height: 279px;
		filter: drop-shadow(0px 4px 19px rgba(0, 0, 0, 0.05));
		background-color: rgba(255, 255, 255, 0.4);
		border: 4px solid transparent;
		border-radius: 15px;
		&:hover {
			border: 4px solid #ec9b3b;
			background-color: rgba(255, 255, 255, 1);
			a {
				color: #ec9b3b;
			}
		}

		@media screen and (max-width: 620px) {
			max-width: 388px;
			width: calc(100% - 20px);
		}
		@media screen and (max-width: 480px) {
			width: 100%;
			max-width: none;
		}
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		padding: 86px;

		font-weight: 800;
		font-size: 27px;
		line-height: 187.5%;
		text-align: center;
		letter-spacing: 0.04em;
		color: #212121;

		@media screen and (max-width: 991px) {
			padding: 50px;
			font-size: 21px;
		}

		p {
			margin: 0;
			max-width: 512px;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++ general-info ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.general__general-info {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.general-info {
	&__wrapper {
		display: flex;
		gap: 40px;

		@media screen and (max-width: 776px) {
			flex-direction: column;
			align-items: center;
		}
	}
	&__left {
		flex-grow: 1;
		min-width: 380px;
		position: -webkit-sticky;
		position: sticky;
		top: 120px;
		align-self: flex-start;

		@media screen and (max-width: 991px) {
			min-width: 320px;
		}

		@media screen and (max-width: 776px) {
			min-width: auto;
			position: relative;
			align-self: center;
			top: 0;
		}

		&_small {
			padding: 0 40px;
		}

		.full-person {
			@media screen and (max-width: 991px) {
				max-width: 320px;
			}
		}
	}
	&__right {
		max-width: 1140px;
		h2 {
			margin: 0;
			margin-bottom: 25px;
			font-weight: 700;
			font-size: 33px;
			line-height: 50px;
			color: #f24c4c;

			@media screen and (max-width: 991px) {
				font-size: 25px;
				line-height: 38px;
			}
			@media screen and (max-width: 480px) {
				font-size: 17px;
				line-height: 30px;
			}
		}
		h3 {
			margin: 0;
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 24px;
			line-height: 36px;
			color: #f24c4c;

			@media screen and (max-width: 776px) {
				font-size: 18px;
				line-height: 24px;
			}
		}
		p {
			line-height: 180%;
			color: #000000;

			&:not(:last-child) {
				margin-bottom: 98px;

				@media screen and (max-width: 991px) {
					margin-bottom: 58px;
				}
			}

			a {
				text-decoration: underline;
				color: #f24c4c;
			}
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
//++++++ chapter-info ++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.blog-inside__chapter-info {
	padding-top: 60px;
	max-width: 841px;
	margin: 0 auto;
}
.chapter-info {
	h3,
	h2,
	h4,
	p {
		margin: 0;
	}
	h2 {
		font-weight: 700;
		font-size: 40px;
		line-height: 60px;
		color: #101f42;
		margin-bottom: 15px;
		@media screen and (max-width: 991px) {
			font-size: 35px;
		}
		@media screen and (max-width: 776px) {
			font-size: 25px;
		}
	}
	h3 {
		color: #f24c4c;
		font-weight: 500;
		font-size: 30px;
		line-height: 125%;
		margin-bottom: 15px;
		@media screen and (max-width: 776px) {
			font-size: 20px;
		}
	}
	h4 {
		color: #f24c4c;
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 10px;

		@media screen and (max-width: 776px) {
			font-size: 18px;
			line-height: 24px;
		}
	}
	p {
		font-size: 18px;
		line-height: 180%;
		color: #000000;
		margin-bottom: 40px;
		a {
			color: #f24c4c;
			text-decoration: underline;
		}
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
//++++++++ rewiews-blog ++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.rewiews-blog {
	padding-top: 150px;
	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
	&_text {
		margin: 0;
		max-width: 991px;
		span {
			color: #f24c4c;
		}
	}
	&__slider {
		position: relative;
	}
	&__slide {
		position: relative;
		font-size: 24.5766px;
		line-height: 37px;
		text-align: center;
		color: #212121;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 68px 80px 75px 80px;

		@media screen and (max-width: 991px) {
			padding: 40px 80px;
			font-size: 20px;
			line-height: 28px;
		}

		@media screen and (max-width: 776px) {
			padding: 38px 0;
		}

		&::after,
		&::before {
			position: absolute;
			content: "";
			width: 197px;
			height: 197px;
			background-image: url("../img/big-red-quote.svg");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

			@media screen and (max-width: 991px) {
				width: 97px;
				height: 97px;
			}
		}
		&::after {
			top: 5px;
			left: 101px;

			@media screen and (max-width: 991px) {
				top: 35px;
				left: 75px;
			}
			@media screen and (max-width: 776px) {
				left: 0;
			}
		}
		&::before {
			bottom: 0;
			right: 150px;
			transform: rotate(180deg);

			@media screen and (max-width: 991px) {
				right: 75px;
			}
			@media screen and (max-width: 776px) {
				right: 0;
			}
		}
	}

	&__name {
		margin: 0;
		margin-bottom: 20px;
		font-weight: 600;
	}

	&__left-bnt,
	&__right-bnt {
		position: absolute;
		cursor: pointer;
		z-index: 2;
		top: 50%;
		transform: translateY(-50%);
		width: 64px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: cover;

		@media screen and (max-width: 776px) {
			display: none;
		}
	}
	&__left-bnt {
		background-image: url("../img/left-red-arrow.svg");
	}
	&__right-bnt {
		right: 0;
		background-image: url("../img/right-red-arrow.svg");
	}
}
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++ post-block  +++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.post-block {
	padding-top: 92px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}

	&__wrapper {
		display: flex;
		gap: 34px;
		padding: 0 30px;

		@media screen and (max-width: 1120px) {
			flex-direction: column;
			align-items: center;
		}
		@media screen and (max-width: 991px) {
			padding: 0;
		}
	}
	&__img {
		width: 100%;
		flex-grow: 1;
		max-width: 910px;
		border-radius: 10px;

		@media screen and (max-width: 1120px) {
			aspect-ratio: 16 / 9;
		}
	}

	&__content {
		padding: 10px 0;
		flex-shrink: 1;
		max-width: 437px;
		letter-spacing: 0.0015em;
		color: #000000;

		@media screen and (max-width: 1120px) {
			max-width: 100%;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0;
		margin-bottom: 17px;
	}

	&__info-tag {
		position: relative;
		font-weight: 700;
		padding-right: 12px;

		&::before {
			position: absolute;
			content: "";
			top: 50%;
			right: 2px;
			transform: translateY(-50%);
			width: 5px;
			height: 5px;
			background-color: #000;
			border-radius: 50%;
		}
	}

	&__info-data {
		font-weight: 500;
	}

	&__title {
		font-weight: 700;
		font-size: 32px;
		line-height: 48px;
		color: #000000;
		margin-bottom: 25px;

		@media screen and (max-width: 991px) {
			font-size: 24px;
			line-height: 40px;
		}
		@media screen and (max-width: 480px) {
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 15px;
		}
	}

	&__text {
		margin: 0;
		font-weight: 300;
		font-size: 26px;
		line-height: 39px;
		margin-bottom: 52px;

		@media screen and (max-width: 991px) {
			font-size: 20px;
			line-height: 33px;
			margin-bottom: 30px;
		}
		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 29px;
			margin-bottom: 20px;
		}
	}

	&__avtor {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	&__avtor-img {
		width: 68px;
		height: 68px;
		border-radius: 50%;

		@media screen and (max-width: 480px) {
			width: 58px;
			height: 58px;
		}
	}
	&__name {
		margin: 0;
		margin-bottom: 6px;
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 25px;
		}
	}

	&__position {
		margin: 0;
		font-weight: 500;

		@media screen and (max-width: 480px) {
			font-size: 14px;
			line-height: 20px;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//+++++++++ person-info +++++++++++++++++++++++++++++++++++++++++++++++++++++//

.blog-inside__person-info {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.person-info {
	&__title {
		text-align: center;
		margin-bottom: 60px;
	}

	&__wrapper {
		display: flex;
		gap: 100px;

		@media screen and (max-width: 1200px) {
			gap: 50px;
		}
		@media screen and (max-width: 660px) {
			flex-direction: column;
		}
	}

	&__img {
		position: -webkit-sticky;
		position: sticky;
		top: 120px;
		align-self: flex-start;
		flex-shrink: 0;
		width: 100%;
		max-width: 550px;
		height: 550px;
		border-radius: 50%;
		overflow: hidden;

		@media screen and (max-width: 991px) {
			max-width: 360px;
			height: 360px;
		}
		@media screen and (max-width: 776px) {
			max-width: 280px;
			height: 280px;
		}
		@media screen and (max-width: 660px) {
			position: static;
			align-self: center;
		}
	}

	&__content {
		padding: 95px 0;
		@media screen and (max-width: 660px) {
			padding: 0;
		}
		h2 {
			margin: 0;
			margin-bottom: 10px;
			font-weight: 700;
			font-size: 30px;
			line-height: 45px;
			color: #212121;
			@media screen and (max-width: 660px) {
				text-align: center;
			}
		}
		p {
			margin: 0;
			margin-bottom: 25px;
			font-size: 18px;
			line-height: 168.5%;
		}
	}

	&__btn {
		padding: 19px 25px;
		font-size: 21px;
		line-height: 33px;

		@media screen and (max-width: 660px) {
			display: block;
			margin: 0 auto;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++ discuss ++++++++++++++++++++++++++++++++++++++++++++++++++++//

.blog-inside__discuss {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}
}
.discuss {
	&__title {
		text-align: center;
		margin-bottom: 50px;
	}

	&__inner {
		max-width: 812px;
		margin: 0 auto;
	}

	&__you {
		display: flex;
		gap: 20px;

		@media screen and (max-width: 480px) {
			flex-direction: column;
		}
	}

	&__img {
		flex-shrink: 0;
		width: 87px;
		height: 87px;
		border-radius: 50%;
	}

	&__form {
		flex-grow: 1;
	}
	&__textarea {
		width: 100%;
		height: 143px;
		padding: 20px;
		margin-bottom: 16px;
		border: 1.5px solid #ababab;
		border-radius: 10px;
		resize: none;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: #ababab;
	}

	&__btn,
	&__btn-all {
		padding: 10px 20px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media screen and (max-width: 480px) {
			padding: 20px 25px;
		}
	}
	&__btn {
		@media screen and (max-width: 480px) {
			display: block;
			margin: 0 auto;
		}
	}
	&__btn-all {
		display: block;
		margin: 0 auto;
	}

	&__other {
		margin-top: 46px;
		padding: 0 54px;
		margin-bottom: 25px;

		@media screen and (max-width: 660px) {
			padding: 0;
		}
	}

	&__message {
		display: flex;
		margin-bottom: 14px;
		gap: 25px;
		@media screen and (max-width: 480px) {
			flex-direction: column;
		}
	}

	&__message-img {
		flex-shrink: 0;
		width: 51px;
		height: 51px;
		border-radius: 50%;
		align-self: flex-end;

		@media screen and (max-width: 480px) {
			align-self: flex-start;
		}
	}

	&__message-text {
		display: flex;
		gap: 25px;
		padding: 25px;
		background: rgba(87, 97, 145, 0.08);
		border-radius: 15.5229px 15.5229px 15.5229px 0px;

		@media screen and (max-width: 480px) {
			flex-direction: column;
		}
		p {
			margin: 0;
			font-size: 18.1101px;
			line-height: 23px;
			color: #6d7287;
		}
		span {
			display: block;
			min-width: 70px;
			font-weight: 500;
			font-size: 15.5229px;
			line-height: 20px;
			color: #23283c;
			text-transform: uppercase;
		}
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++ careers-calc  ++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.careers-calc {
	position: relative;
	margin-top: -310px;
	padding-top: 460px;
	@media screen and (max-width: 991px) {
		padding-top: 100px;
		margin-top: 0;
	}

	&__title {
		text-align: center;
		margin-bottom: 50px;
	}

	&__form {
		display: grid;

		grid-template-columns: 1fr 2fr;
		gap: 20px;

		@media screen and (max-width: 1200px) {
			gap: 10px;
		}
		@media screen and (max-width: 991px) {
			justify-content: center;
			grid-template-columns: 0.6fr;
		}
		@media screen and (max-width: 620px) {
			grid-template-columns: 0.8fr;
		}
		@media screen and (max-width: 480px) {
			grid-template-columns: 1fr;
		}
	}
	&__form-section {
		display: flex;
		flex-direction: column;
		padding: 30px 30px 46px;
		background: #fff;
		border-radius: 20px;
		box-shadow: 0 4px 112px rgb(219 49 54 / 5%);
	}
	&__section-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 39px;

		@media screen and (max-width: 480px) {
			flex-direction: column;
			gap: 30px;
		}
	}
	&__pos {
		display: flex;
		gap: 40px;
		li {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			font-size: 16px;
			line-height: 125.5%;
			width: 35px;
			height: 35px;
			background: #f4f4f4;
			border-radius: 50%;
			color: #b0b0b0;

			&:not(:first-child) {
				position: relative;
				&::after {
					position: absolute;
					content: "";
					width: 40px;
					height: 2px;
					background: #f4f4f4;
					top: 50%;
					left: -115%;
					transform: translateY(-50%);
				}
			}

			&.active {
				color: #fff;
				background: #f24c4c;

				&::after {
					background: #f24c4c;
				}
			}
		}
	}

	&__form-block {
		width: 100%;
		height: 100%;
	}

	&__form-title {
		max-width: 500px;
		&_left {
			text-align: left;
		}
	}

	&__block-wrapper {
		display: flex;
		gap: 35px;
		height: 100%;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			gap: 65px;
		}
	}

	&__block-left {
		display: flex;
		flex-direction: column;
		max-width: 380px;
		flex-grow: 1;
		@media screen and (max-width: 991px) {
			max-width: 100%;
		}
	}

	&__form-subtitle,
	&__form-label {
		margin: 0;
		display: flex;

		gap: 12px;
		margin-bottom: 14px;
		color: #212121;
		font-weight: 500;
		font-size: 16px;
		line-height: 125.5%;
		text-align: left;
		p {
			margin: 0;
		}
	}
	&__form-subtitle {
		flex-direction: row;
		&_red {
			color: #f24c4c;
		}
		span {
			color: #f24c4c;
		}
	}

	&__form-label {
		position: relative;
		flex-direction: column;
		&_row {
			flex-direction: row;
			align-items: center;
		}

		&_ratio {
			position: relative;
			padding-left: 28px;
		}

		& input:checked ~ .careers-calc__radio-box {
			background: #f24c4c;
			border: 1px solid transparent;
			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 6px;
				height: 6px;
				background: #fff;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				content: "";
			}
		}
	}

	&__form-input {
		width: 100%;
		padding: 12px 21px;
		color: #000;
		font-weight: 400;
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		&_medium {
			max-width: 160px;
		}
		&_small {
			max-width: 80px;
		}
	}

	&__form-textarea {
		height: 141px;
		margin-top: 12px;
		margin-bottom: 20px;
		padding: 12px 21px;
		color: #000;
		font-weight: 400;
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		resize: none;
	}

	&__btn {
		width: 100%;
		padding: 14px 40px;
		font-weight: 600;
		font-size: 20px;
		line-height: 125.5%;
		margin-top: auto;
	}

	&__block-right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 338px;
		flex-grow: 1;
	}

	&__input-wrapper {
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		span {
			font-weight: 300;
			color: #000;
			font-size: 25px;
		}
	}

	&__pair-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 15px;
	}

	&__radio-wrapper {
		display: flex;
		gap: 10px;
	}

	&__form-radio {
		position: absolute;
		width: 0;
		height: 0;
		cursor: pointer;
		opacity: 0;
	}
	&__radio-box {
		position: absolute;
		top: 50%;
		left: 0;
		width: 18px;
		height: 18px;
		border: 1px solid #d9d9d9;
		border-radius: 50%;
		transform: translateY(-50%);
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
.careers-initiative {
	padding-top: 150px;

	@media screen and (max-width: 991px) {
		padding-top: 80px;
	}

	&__title {
		text-align: center;
		margin-bottom: 70px;
	}

	&__wrapper {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 80px;

		@media screen and (max-width: 991px) {
			justify-content: center;
			grid-template-columns: 0.6fr;
		}
		@media screen and (max-width: 620px) {
			grid-template-columns: 0.8fr;
		}
		@media screen and (max-width: 480px) {
			grid-template-columns: 1fr;
		}
	}
	&__img {
		@media screen and (max-width: 991px) {
			display: none;
		}
	}

	&__form {
		flex-grow: 1;
	}

	&__label,
	&__subtitle {
		margin: 0;
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 14px;
		color: #212121;
		font-weight: 500;
		font-size: 16px;
		line-height: 125.5%;
		text-align: left;
	}

	&__input,
	&__area {
		width: 100%;
		padding: 12px 21px;
		color: #000;
		font-weight: 400;
		border: 1px solid #d8d8d8;
		border-radius: 4px;
	}

	&__area {
		height: 135px;
		resize: none;
	}

	&__drag {
		margin-bottom: 12px;
		padding: 30px 46px;
	}

	&__btn {
		width: 100%;
		padding: 14px 40px;
		font-weight: 600;
		font-size: 20px;
		line-height: 125.5%;
		margin-top: 11px;
	}
}

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++//
